//
// Variable overrides
//

// This file is intentionally blank
// Use this file to override any core SCSS variables

//$primary: #fff;
$font-family-base: 'Qatar2022', sans-serif;
$link-color: #fff;
$navbar-nav-link-padding-x: 1rem;
$headings-color: #fff;



// dropdown
$dropdown-dark-bg: #6C3647;
//$dropdown-dark-link-hover-bg: #570F2E;
$dropdown-dark-link-active-bg: #3A071C;


// pagination
$pagination-color: #3A071C;
$pagination-focus-color: #570F2E;
$pagination-hover-color: #570F2E;
