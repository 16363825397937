//
// Project styles
//

@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
//@import "@fortawesome/fontawesome-pro/scss/brands.scss";

// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS
// for creating your own CSS components
@font-face {
  font-family: 'Qatar2022';
  font-style: normal;
  font-weight: 700;
  src: local('Qatar2022'), url('../fonts/Qatar2022Bold.woff') format('woff');
}

@font-face {
  font-family: 'Qatar2022';
  font-style: normal;
  font-weight: 900;
  src: local('Qatar2022'), url('../fonts/Qatar2022Heavy.woff') format('woff');
}


body {
  background: rgb(144, 17, 47);
  background: radial-gradient(circle, rgba(144, 17, 47, 1) 0%, rgba(108, 14, 40, 1) 50%, rgba(55, 6, 27, 1) 100%);
  color: #fff;
}

.gradient-background {
  background: rgb(144, 17, 47);
  background: radial-gradient(circle, rgba(144, 17, 47, 1) 0%, rgba(108, 14, 40, 1) 50%, rgba(55, 6, 27, 1) 100%);
}

.no-transition {
  transition: none;
}

body img.wc-logo {
  max-width: 240px;
}

body img.ow-logo {
  max-width: 280px;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name,
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email {
  max-width: 12rem;
}

.match {
  .team-name {
    font-size: 1.5rem;
    width: 180px;
    max-width: 180px;

    @include media-breakpoint-up(md) {
      min-height: 72px;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
  }

  .vs {
    font-size: 3rem;
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    text-shadow: 0 3px 12px rgb(255 255 255);

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  .goals {
    font-size: 3.5rem;
    color: #fff;

    @include media-breakpoint-only(xs) {
      font-size: 1.5rem;
    }
  }

  .score input {
    font-size: 3rem;
    max-width: 4rem;


    @include media-breakpoint-only(xs) {
      font-size: 1.5rem;
    }

  }

  input.corners, input.yellow-cards {
    max-width: 2rem;
  }

  .progress {
    height: 2rem;
  }
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}


.match.card {
  --bs-bg-opacity: 0.8;
}

.nav-pills.matches {
  --bs-nav-pills-link-active-bg: #3A071C;
}

.match {
  .progress, .legend {
    .bg-1 {
      background-color: #3B4309 !important;
    }

    .bg-2 {
      background-color: #09432E !important;
    }

    .bg-3 {
      background-color: #110943 !important;
    }
  }
}

.pagination {
  justify-content: center;
}


@include media-breakpoint-up(md) {
  .offcanvas.gradient-background {
    background: none;
  }
}

@include media-breakpoint-only(xs) {
  .pagination {
    .page-item:not(:first-child):not(:last-child):not(.active) {
      display: none;
    }
  }
}


.navbar {
  .dropdown-menu {
    top: calc(100%) !important;
  }
}

.img-icon {
  max-width: 2rem;
}

.invalid-feedback {
  background-color: #fff;
  padding: 4px;
}

.legend .square  {
  width: 1rem;
  height: 1rem;
}





/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#start_game {

  .rounded-circle {
    aspect-ratio: 1;
  }
  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio] + div {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type=radio]:checked + div {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.40);
  }

  img.start-logo {
    width: auto;
    height: auto;
    max-width: 64px;
    max-height: 64px;

    @include media-breakpoint-up(md) {
      max-width: 80px;
      max-height: 80px;
    }

  }

}

.dropdown-user {
  min-width: 280px;
}

.team-logo-container {
  @include media-breakpoint-only(sm) {
    min-height: 48px;
    max-height: 48px;
  }
  @include media-breakpoint-up(md) {
    min-height: 80px;
    max-height: 80px;
  }

  img.team-logo {
    max-width: 120px;
    max-height: 80px;

    @include media-breakpoint-down(md) {
      max-width: 64px;
      max-height: 48px;
    }
  }
}


#navbar-top.fixed-top {
  border-bottom: 1px solid #fff;
  background-color: #3A071C;
}
