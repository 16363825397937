//
// Color overrides
//

// This file is intentionally blank
// Use this file to override any color variables within the project

// You can also replace this file with a file exported from the customizer,
// which you can access from the widget at https://themes.startbootstrap.com/sb-admin-pro/

// * * * * * * * *
// * * Example * *
// * * * * * * * *

// Uncomment below and rebuild the SCSS to see color changes in effect

$primary: #3A071C;
$secondary: #6C3647;
