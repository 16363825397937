@charset "UTF-8";
/*!
 * Bootstrap  v5.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #69707a;
  --bs-gray-dark: #363d47;
  --bs-gray-100: #f2f6fc;
  --bs-gray-200: #e0e5ec;
  --bs-gray-300: #d4dae3;
  --bs-gray-400: #c5ccd6;
  --bs-gray-500: #a7aeb8;
  --bs-gray-600: #69707a;
  --bs-gray-700: #4a515b;
  --bs-gray-800: #363d47;
  --bs-gray-900: #212832;
  --bs-primary: #3A071C;
  --bs-secondary: #6C3647;
  --bs-success: #00ac69;
  --bs-info: #00cfd5;
  --bs-warning: #f4a100;
  --bs-danger: #e81500;
  --bs-light: #f2f6fc;
  --bs-dark: #212832;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red-soft: #f1e0e3;
  --bs-orange-soft: #f3e7e3;
  --bs-yellow-soft: #f2eee3;
  --bs-green-soft: #daefed;
  --bs-teal-soft: #daf0f2;
  --bs-cyan-soft: #daf2f8;
  --bs-blue-soft: #dae7fb;
  --bs-indigo-soft: #e3ddfa;
  --bs-purple-soft: #e4ddf7;
  --bs-pink-soft: #f1ddec;
  --bs-primary-soft: #e0dee6;
  --bs-secondary-soft: #e5e3ea;
  --bs-success-soft: #daefed;
  --bs-info-soft: #daf2f8;
  --bs-warning-soft: #f2eee3;
  --bs-danger-soft: #f1e0e3;
  --bs-primary-rgb: 58, 7, 28;
  --bs-secondary-rgb: 108, 54, 71;
  --bs-success-rgb: 0, 172, 105;
  --bs-info-rgb: 0, 207, 213;
  --bs-warning-rgb: 244, 161, 0;
  --bs-danger-rgb: 232, 21, 0;
  --bs-light-rgb: 242, 246, 252;
  --bs-dark-rgb: 33, 40, 50;
  --bs-black-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-red-rgb: 232, 21, 0;
  --bs-orange-rgb: 247, 100, 0;
  --bs-yellow-rgb: 244, 161, 0;
  --bs-green-rgb: 0, 172, 105;
  --bs-teal-rgb: 0, 186, 148;
  --bs-cyan-rgb: 0, 207, 213;
  --bs-blue-rgb: 0, 97, 242;
  --bs-indigo-rgb: 88, 0, 232;
  --bs-purple-rgb: 105, 0, 199;
  --bs-pink-rgb: 227, 0, 89;
  --bs-red-soft-rgb: 241, 224, 227;
  --bs-orange-soft-rgb: 243, 231, 227;
  --bs-yellow-soft-rgb: 242, 238, 227;
  --bs-green-soft-rgb: 218, 239, 237;
  --bs-teal-soft-rgb: 218, 240, 242;
  --bs-cyan-soft-rgb: 218, 242, 248;
  --bs-blue-soft-rgb: 218, 231, 251;
  --bs-indigo-soft-rgb: 227, 221, 250;
  --bs-purple-soft-rgb: 228, 221, 247;
  --bs-pink-soft-rgb: 241, 221, 236;
  --bs-primary-soft-rgb: 224, 222, 230;
  --bs-secondary-soft-rgb: 229, 227, 234;
  --bs-success-soft-rgb: 218, 239, 237;
  --bs-info-soft-rgb: 218, 242, 248;
  --bs-warning-soft-rgb: 242, 238, 227;
  --bs-danger-soft-rgb: 241, 224, 227;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 105, 112, 122;
  --bs-body-bg-rgb: 242, 246, 252;
  --bs-font-sans-serif: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Qatar2022, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #69707a;
  --bs-body-bg: #f2f6fc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e0e5ec;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.35rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #fff;
  --bs-link-hover-color: #cccccc;
  --bs-code-color: #e30059;
  --bs-highlight-bg: #fdeccc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
}

h1, .h1 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.4rem;
  }
}

h3, .h3 {
  font-size: calc(1.255rem + 0.06vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.3rem;
  }
}

h4, .h4 {
  font-size: 1.2rem;
}

h5, .h5 {
  font-size: 1.1rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #69707a;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #a7aeb8;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.1rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #69707a;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f2f6fc;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.35rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #69707a;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2.5rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 9rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 9rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 6rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 6rem;
  }

  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 9rem;
  }

  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 9rem;
  }
}
.table, .dataTable-table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: #e0e5ec;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(224, 229, 236, 0.25);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > *, .dataTable-table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody, .dataTable-table > tbody {
  vertical-align: inherit;
}
.table > thead, .dataTable-table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > *, .dataTable-table > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > *, .dataTable-table > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > *, .dataTable-table > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d8cdd2;
  --bs-table-border-color: #c2b9bd;
  --bs-table-striped-bg: #cdc3c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2b9bd;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c8bec2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2d7da;
  --bs-table-border-color: #cbc2c4;
  --bs-table-striped-bg: #d7cccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #cceee1;
  --bs-table-border-color: #b8d6cb;
  --bs-table-striped-bg: #c2e2d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d6cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddcd0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccf5f7;
  --bs-table-border-color: #b8ddde;
  --bs-table-striped-bg: #c2e9eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8ddde;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde3e4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fdeccc;
  --bs-table-border-color: #e4d4b8;
  --bs-table-striped-bg: #f0e0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eadabd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fad0cc;
  --bs-table-border-color: #e1bbb8;
  --bs-table-striped-bg: #eec6c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1bbb8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7c0bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f2f6fc;
  --bs-table-border-color: #dadde3;
  --bs-table-striped-bg: #e6eaef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dadde3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e0e4e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212832;
  --bs-table-border-color: #373e47;
  --bs-table-striped-bg: #2c333c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373e47;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323841;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive, .dataTable-wrapper .dataTable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1499.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #a7aeb8;
}

.form-control, .dataTable-input {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .dataTable-input {
    transition: none;
  }
}
.form-control[type=file], [type=file].dataTable-input {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), [type=file].dataTable-input:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .dataTable-input:focus {
  color: #69707a;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.form-control::-webkit-date-and-time-value, .dataTable-input::-webkit-date-and-time-value {
  height: 1em;
}
.form-control::placeholder, .dataTable-input::placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.form-control:disabled, .dataTable-input:disabled {
  background-color: #e0e5ec;
  opacity: 1;
}
.form-control::file-selector-button, .dataTable-input::file-selector-button {
  padding: 0.875rem 1.125rem;
  margin: -0.875rem -1.125rem;
  margin-inline-end: 1.125rem;
  color: #69707a;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .dataTable-input::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.875rem 0;
  margin-bottom: 0;
  line-height: 1;
  color: #69707a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1em + 2.25rem + 2px);
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 1.125rem 1.5rem;
  margin: -1.125rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control, textarea.dataTable-input {
  min-height: calc(1em + 1.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1em + 1rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1em + 2.25rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1em + 1.75rem + 2px);
  padding: 0.875rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.35rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.35rem;
}
.form-control-color.form-control-sm {
  height: calc(1em + 1rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1em + 2.25rem + 2px);
}

.form-select, .dataTable-selector {
  display: block;
  width: 100%;
  padding: 0.875rem 3.375rem 0.875rem 1.125rem;
  -moz-padding-start: calc(1.125rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  background-size: 16px 12px;
  border: 1px solid #c5ccd6;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select, .dataTable-selector {
    transition: none;
  }
}
.form-select:focus, .dataTable-selector:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.form-select[multiple], [multiple].dataTable-selector, .form-select[size]:not([size="1"]), [size].dataTable-selector:not([size="1"]) {
  padding-right: 1.125rem;
  background-image: none;
}
.form-select:disabled, .dataTable-selector:disabled {
  background-color: #e0e5ec;
}
.form-select:-moz-focusring, .dataTable-selector:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #69707a;
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.form-check-input:checked {
  background-color: #3A071C;
  border-color: #3A071C;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #3A071C;
  border-color: #3A071C;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='transparent'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f2f6fc, 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f2f6fc, 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3A071C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c4b5bb;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d4dae3;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3A071C;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c4b5bb;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d4dae3;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #a7aeb8;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #a7aeb8;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > .dataTable-input,
.form-floating > .form-control-plaintext,
.form-floating > .form-select,
.form-floating > .dataTable-selector {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1.125rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .form-floating > .dataTable-input,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.125rem;
}
.form-floating > .form-control::placeholder, .form-floating > .dataTable-input::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .dataTable-input:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .dataTable-input:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .form-floating > .dataTable-input:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select, .form-floating > .dataTable-selector {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label, .form-floating > .dataTable-input:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .dataTable-input:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > .dataTable-selector ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label, .form-floating > .dataTable-input:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > .dataTable-input,
.input-group > .form-select,
.input-group > .dataTable-selector,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > .dataTable-input:focus,
.input-group > .form-select:focus,
.input-group > .dataTable-selector:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #c5ccd6;
  border-radius: 0.35rem;
}

.input-group-lg > .form-control, .input-group-lg > .dataTable-input,
.input-group-lg > .form-select,
.input-group-lg > .dataTable-selector,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control, .input-group-sm > .dataTable-input,
.input-group-sm > .form-select,
.input-group-sm > .dataTable-selector,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select, .input-group-lg > .dataTable-selector,
.input-group-sm > .form-select,
.input-group-sm > .dataTable-selector {
  padding-right: 4.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-input,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .dataTable-input,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .dataTable-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .dataTable-input,
.input-group > .form-floating:not(:first-child) > .form-select,
.input-group > .form-floating:not(:first-child) > .dataTable-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00ac69;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(0, 172, 105, 0.9);
  border-radius: 0.35rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .dataTable-input:valid, .form-control.is-valid, .is-valid.dataTable-input {
  border-color: #00ac69;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-control:valid:focus, .was-validated .dataTable-input:valid:focus, .form-control.is-valid:focus, .is-valid.dataTable-input:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}

.was-validated textarea.form-control:valid, .was-validated textarea.dataTable-input:valid, textarea.form-control.is-valid, textarea.is-valid.dataTable-input {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}

.was-validated .form-select:valid, .was-validated .dataTable-selector:valid, .form-select.is-valid, .is-valid.dataTable-selector {
  border-color: #00ac69;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .dataTable-selector:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated .dataTable-selector:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .is-valid.dataTable-selector:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .is-valid.dataTable-selector:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-select:valid:focus, .was-validated .dataTable-selector:valid:focus, .form-select.is-valid:focus, .is-valid.dataTable-selector:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1em + 1.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00ac69;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00ac69;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00ac69;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .dataTable-input:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > .dataTable-input:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.was-validated .input-group > .dataTable-selector:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.input-group > .dataTable-selector:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e81500;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(232, 21, 0, 0.9);
  border-radius: 0.35rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .dataTable-input:invalid, .form-control.is-invalid, .is-invalid.dataTable-input {
  border-color: #e81500;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e81500'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-control:invalid:focus, .was-validated .dataTable-input:invalid:focus, .form-control.is-invalid:focus, .is-invalid.dataTable-input:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}

.was-validated textarea.form-control:invalid, .was-validated textarea.dataTable-input:invalid, textarea.form-control.is-invalid, textarea.is-invalid.dataTable-input {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}

.was-validated .form-select:invalid, .was-validated .dataTable-selector:invalid, .form-select.is-invalid, .is-invalid.dataTable-selector {
  border-color: #e81500;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .dataTable-selector:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated .dataTable-selector:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .is-invalid.dataTable-selector:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .is-invalid.dataTable-selector:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e81500'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.was-validated .form-select:invalid:focus, .was-validated .dataTable-selector:invalid:focus, .form-select.is-invalid:focus, .is-invalid.dataTable-selector:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1em + 1.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #e81500;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #e81500;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e81500;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .dataTable-input:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .dataTable-input:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.was-validated .input-group > .dataTable-selector:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.input-group > .dataTable-selector:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1;
  --bs-btn-color: #69707a;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.35rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3A071C;
  --bs-btn-border-color: #3A071C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #310618;
  --bs-btn-hover-border-color: #2e0616;
  --bs-btn-focus-shadow-rgb: 88, 44, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e0616;
  --bs-btn-active-border-color: #2c0515;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3A071C;
  --bs-btn-disabled-border-color: #3A071C;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6C3647;
  --bs-btn-border-color: #6C3647;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c2e3c;
  --bs-btn-hover-border-color: #562b39;
  --bs-btn-focus-shadow-rgb: 130, 84, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #562b39;
  --bs-btn-active-border-color: #512935;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6C3647;
  --bs-btn-disabled-border-color: #6C3647;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00ac69;
  --bs-btn-border-color: #00ac69;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #009259;
  --bs-btn-hover-border-color: #008a54;
  --bs-btn-focus-shadow-rgb: 38, 184, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #008a54;
  --bs-btn-active-border-color: #00814f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00ac69;
  --bs-btn-disabled-border-color: #00ac69;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00cfd5;
  --bs-btn-border-color: #00cfd5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00b0b5;
  --bs-btn-hover-border-color: #00a6aa;
  --bs-btn-focus-shadow-rgb: 38, 214, 219;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00a6aa;
  --bs-btn-active-border-color: #009ba0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00cfd5;
  --bs-btn-disabled-border-color: #00cfd5;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f4a100;
  --bs-btn-border-color: #f4a100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cf8900;
  --bs-btn-hover-border-color: #c38100;
  --bs-btn-focus-shadow-rgb: 246, 175, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c38100;
  --bs-btn-active-border-color: #b77900;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f4a100;
  --bs-btn-disabled-border-color: #f4a100;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e81500;
  --bs-btn-border-color: #e81500;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c51200;
  --bs-btn-hover-border-color: #ba1100;
  --bs-btn-focus-shadow-rgb: 235, 56, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ba1100;
  --bs-btn-active-border-color: #ae1000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e81500;
  --bs-btn-disabled-border-color: #e81500;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2f6fc;
  --bs-btn-border-color: #f2f6fc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ced1d6;
  --bs-btn-hover-border-color: #c2c5ca;
  --bs-btn-focus-shadow-rgb: 206, 209, 214;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c2c5ca;
  --bs-btn-active-border-color: #b6b9bd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2f6fc;
  --bs-btn-disabled-border-color: #f2f6fc;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212832;
  --bs-btn-border-color: #212832;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424851;
  --bs-btn-hover-border-color: #373e47;
  --bs-btn-focus-shadow-rgb: 66, 72, 81;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d535b;
  --bs-btn-active-border-color: #373e47;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212832;
  --bs-btn-disabled-border-color: #212832;
}

.btn-black {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e81500;
  --bs-btn-border-color: #e81500;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c51200;
  --bs-btn-hover-border-color: #ba1100;
  --bs-btn-focus-shadow-rgb: 235, 56, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ba1100;
  --bs-btn-active-border-color: #ae1000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e81500;
  --bs-btn-disabled-border-color: #e81500;
}

.btn-orange {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f76400;
  --bs-btn-border-color: #f76400;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d25500;
  --bs-btn-hover-border-color: #c65000;
  --bs-btn-focus-shadow-rgb: 248, 123, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c65000;
  --bs-btn-active-border-color: #b94b00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f76400;
  --bs-btn-disabled-border-color: #f76400;
}

.btn-yellow {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f4a100;
  --bs-btn-border-color: #f4a100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cf8900;
  --bs-btn-hover-border-color: #c38100;
  --bs-btn-focus-shadow-rgb: 246, 175, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c38100;
  --bs-btn-active-border-color: #b77900;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f4a100;
  --bs-btn-disabled-border-color: #f4a100;
}

.btn-green {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00ac69;
  --bs-btn-border-color: #00ac69;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #009259;
  --bs-btn-hover-border-color: #008a54;
  --bs-btn-focus-shadow-rgb: 38, 184, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #008a54;
  --bs-btn-active-border-color: #00814f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00ac69;
  --bs-btn-disabled-border-color: #00ac69;
}

.btn-teal {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00ba94;
  --bs-btn-border-color: #00ba94;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #009e7e;
  --bs-btn-hover-border-color: #009576;
  --bs-btn-focus-shadow-rgb: 38, 196, 164;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #009576;
  --bs-btn-active-border-color: #008c6f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00ba94;
  --bs-btn-disabled-border-color: #00ba94;
}

.btn-cyan {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00cfd5;
  --bs-btn-border-color: #00cfd5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00b0b5;
  --bs-btn-hover-border-color: #00a6aa;
  --bs-btn-focus-shadow-rgb: 38, 214, 219;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00a6aa;
  --bs-btn-active-border-color: #009ba0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00cfd5;
  --bs-btn-disabled-border-color: #00cfd5;
}

.btn-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0061f2;
  --bs-btn-border-color: #0061f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0052ce;
  --bs-btn-hover-border-color: #004ec2;
  --bs-btn-focus-shadow-rgb: 38, 121, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004ec2;
  --bs-btn-active-border-color: #0049b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0061f2;
  --bs-btn-disabled-border-color: #0061f2;
}

.btn-indigo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5800e8;
  --bs-btn-border-color: #5800e8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4b00c5;
  --bs-btn-hover-border-color: #4600ba;
  --bs-btn-focus-shadow-rgb: 113, 38, 235;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4600ba;
  --bs-btn-active-border-color: #4200ae;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5800e8;
  --bs-btn-disabled-border-color: #5800e8;
}

.btn-purple {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6900c7;
  --bs-btn-border-color: #6900c7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5900a9;
  --bs-btn-hover-border-color: #54009f;
  --bs-btn-focus-shadow-rgb: 128, 38, 207;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #54009f;
  --bs-btn-active-border-color: #4f0095;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6900c7;
  --bs-btn-disabled-border-color: #6900c7;
}

.btn-pink {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e30059;
  --bs-btn-border-color: #e30059;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c1004c;
  --bs-btn-hover-border-color: #b60047;
  --bs-btn-focus-shadow-rgb: 231, 38, 114;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b60047;
  --bs-btn-active-border-color: #aa0043;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e30059;
  --bs-btn-disabled-border-color: #e30059;
}

.btn-red-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f1e0e3;
  --bs-btn-border-color: #f1e0e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f3e5e7;
  --bs-btn-hover-border-color: #f2e3e6;
  --bs-btn-focus-shadow-rgb: 205, 190, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4e6e9;
  --bs-btn-active-border-color: #f2e3e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f1e0e3;
  --bs-btn-disabled-border-color: #f1e0e3;
}

.btn-orange-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f3e7e3;
  --bs-btn-border-color: #f3e7e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f5ebe7;
  --bs-btn-hover-border-color: #f4e9e6;
  --bs-btn-focus-shadow-rgb: 207, 196, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5ece9;
  --bs-btn-active-border-color: #f4e9e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f3e7e3;
  --bs-btn-disabled-border-color: #f3e7e3;
}

.btn-yellow-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2eee3;
  --bs-btn-border-color: #f2eee3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4f1e7;
  --bs-btn-hover-border-color: #f3f0e6;
  --bs-btn-focus-shadow-rgb: 206, 202, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5f1e9;
  --bs-btn-active-border-color: #f3f0e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2eee3;
  --bs-btn-disabled-border-color: #f2eee3;
}

.btn-green-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #daefed;
  --bs-btn-border-color: #daefed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0f1f0;
  --bs-btn-hover-border-color: #def1ef;
  --bs-btn-focus-shadow-rgb: 185, 203, 201;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1f2f1;
  --bs-btn-active-border-color: #def1ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #daefed;
  --bs-btn-disabled-border-color: #daefed;
}

.btn-teal-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #daf0f2;
  --bs-btn-border-color: #daf0f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0f2f4;
  --bs-btn-hover-border-color: #def2f3;
  --bs-btn-focus-shadow-rgb: 185, 204, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1f3f5;
  --bs-btn-active-border-color: #def2f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #daf0f2;
  --bs-btn-disabled-border-color: #daf0f2;
}

.btn-cyan-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #daf2f8;
  --bs-btn-border-color: #daf2f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0f4f9;
  --bs-btn-hover-border-color: #def3f9;
  --bs-btn-focus-shadow-rgb: 185, 206, 211;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1f5f9;
  --bs-btn-active-border-color: #def3f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #daf2f8;
  --bs-btn-disabled-border-color: #daf2f8;
}

.btn-blue-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #dae7fb;
  --bs-btn-border-color: #dae7fb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0ebfc;
  --bs-btn-hover-border-color: #dee9fb;
  --bs-btn-focus-shadow-rgb: 185, 196, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1ecfc;
  --bs-btn-active-border-color: #dee9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #dae7fb;
  --bs-btn-disabled-border-color: #dae7fb;
}

.btn-indigo-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #e3ddfa;
  --bs-btn-border-color: #e3ddfa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e7e2fb;
  --bs-btn-hover-border-color: #e6e0fb;
  --bs-btn-focus-shadow-rgb: 193, 188, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9e4fb;
  --bs-btn-active-border-color: #e6e0fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e3ddfa;
  --bs-btn-disabled-border-color: #e3ddfa;
}

.btn-purple-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #e4ddf7;
  --bs-btn-border-color: #e4ddf7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e8e2f8;
  --bs-btn-hover-border-color: #e7e0f8;
  --bs-btn-focus-shadow-rgb: 194, 188, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9e4f9;
  --bs-btn-active-border-color: #e7e0f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e4ddf7;
  --bs-btn-disabled-border-color: #e4ddf7;
}

.btn-pink-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f1ddec;
  --bs-btn-border-color: #f1ddec;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f3e2ef;
  --bs-btn-hover-border-color: #f2e0ee;
  --bs-btn-focus-shadow-rgb: 205, 188, 201;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4e4f0;
  --bs-btn-active-border-color: #f2e0ee;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f1ddec;
  --bs-btn-disabled-border-color: #f1ddec;
}

.btn-primary-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #e0dee6;
  --bs-btn-border-color: #e0dee6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e3ea;
  --bs-btn-hover-border-color: #e3e1e9;
  --bs-btn-focus-shadow-rgb: 190, 189, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e6e5eb;
  --bs-btn-active-border-color: #e3e1e9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e0dee6;
  --bs-btn-disabled-border-color: #e0dee6;
}

.btn-secondary-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #e5e3ea;
  --bs-btn-border-color: #e5e3ea;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9e7ed;
  --bs-btn-hover-border-color: #e8e6ec;
  --bs-btn-focus-shadow-rgb: 195, 193, 199;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eae9ee;
  --bs-btn-active-border-color: #e8e6ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e5e3ea;
  --bs-btn-disabled-border-color: #e5e3ea;
}

.btn-success-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #daefed;
  --bs-btn-border-color: #daefed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0f1f0;
  --bs-btn-hover-border-color: #def1ef;
  --bs-btn-focus-shadow-rgb: 185, 203, 201;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1f2f1;
  --bs-btn-active-border-color: #def1ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #daefed;
  --bs-btn-disabled-border-color: #daefed;
}

.btn-info-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #daf2f8;
  --bs-btn-border-color: #daf2f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0f4f9;
  --bs-btn-hover-border-color: #def3f9;
  --bs-btn-focus-shadow-rgb: 185, 206, 211;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1f5f9;
  --bs-btn-active-border-color: #def3f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #daf2f8;
  --bs-btn-disabled-border-color: #daf2f8;
}

.btn-warning-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2eee3;
  --bs-btn-border-color: #f2eee3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4f1e7;
  --bs-btn-hover-border-color: #f3f0e6;
  --bs-btn-focus-shadow-rgb: 206, 202, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5f1e9;
  --bs-btn-active-border-color: #f3f0e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2eee3;
  --bs-btn-disabled-border-color: #f2eee3;
}

.btn-danger-soft {
  --bs-btn-color: #000;
  --bs-btn-bg: #f1e0e3;
  --bs-btn-border-color: #f1e0e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f3e5e7;
  --bs-btn-hover-border-color: #f2e3e6;
  --bs-btn-focus-shadow-rgb: 205, 190, 193;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4e6e9;
  --bs-btn-active-border-color: #f2e3e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f1e0e3;
  --bs-btn-disabled-border-color: #f1e0e3;
}

.btn-outline-primary {
  --bs-btn-color: #3A071C;
  --bs-btn-border-color: #3A071C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3A071C;
  --bs-btn-hover-border-color: #3A071C;
  --bs-btn-focus-shadow-rgb: 58, 7, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3A071C;
  --bs-btn-active-border-color: #3A071C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3A071C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3A071C;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6C3647;
  --bs-btn-border-color: #6C3647;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6C3647;
  --bs-btn-hover-border-color: #6C3647;
  --bs-btn-focus-shadow-rgb: 108, 54, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6C3647;
  --bs-btn-active-border-color: #6C3647;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6C3647;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6C3647;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #00ac69;
  --bs-btn-border-color: #00ac69;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00ac69;
  --bs-btn-hover-border-color: #00ac69;
  --bs-btn-focus-shadow-rgb: 0, 172, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00ac69;
  --bs-btn-active-border-color: #00ac69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00ac69;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00ac69;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00cfd5;
  --bs-btn-border-color: #00cfd5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00cfd5;
  --bs-btn-hover-border-color: #00cfd5;
  --bs-btn-focus-shadow-rgb: 0, 207, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00cfd5;
  --bs-btn-active-border-color: #00cfd5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00cfd5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00cfd5;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #f4a100;
  --bs-btn-border-color: #f4a100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f4a100;
  --bs-btn-hover-border-color: #f4a100;
  --bs-btn-focus-shadow-rgb: 244, 161, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f4a100;
  --bs-btn-active-border-color: #f4a100;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f4a100;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f4a100;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #e81500;
  --bs-btn-border-color: #e81500;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e81500;
  --bs-btn-hover-border-color: #e81500;
  --bs-btn-focus-shadow-rgb: 232, 21, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e81500;
  --bs-btn-active-border-color: #e81500;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e81500;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e81500;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f2f6fc;
  --bs-btn-border-color: #f2f6fc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2f6fc;
  --bs-btn-hover-border-color: #f2f6fc;
  --bs-btn-focus-shadow-rgb: 242, 246, 252;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2f6fc;
  --bs-btn-active-border-color: #f2f6fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f2f6fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2f6fc;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212832;
  --bs-btn-border-color: #212832;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212832;
  --bs-btn-hover-border-color: #212832;
  --bs-btn-focus-shadow-rgb: 33, 40, 50;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212832;
  --bs-btn-active-border-color: #212832;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212832;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212832;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #e81500;
  --bs-btn-border-color: #e81500;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e81500;
  --bs-btn-hover-border-color: #e81500;
  --bs-btn-focus-shadow-rgb: 232, 21, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e81500;
  --bs-btn-active-border-color: #e81500;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e81500;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e81500;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #f76400;
  --bs-btn-border-color: #f76400;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f76400;
  --bs-btn-hover-border-color: #f76400;
  --bs-btn-focus-shadow-rgb: 247, 100, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f76400;
  --bs-btn-active-border-color: #f76400;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f76400;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f76400;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #f4a100;
  --bs-btn-border-color: #f4a100;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f4a100;
  --bs-btn-hover-border-color: #f4a100;
  --bs-btn-focus-shadow-rgb: 244, 161, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f4a100;
  --bs-btn-active-border-color: #f4a100;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f4a100;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f4a100;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #00ac69;
  --bs-btn-border-color: #00ac69;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00ac69;
  --bs-btn-hover-border-color: #00ac69;
  --bs-btn-focus-shadow-rgb: 0, 172, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00ac69;
  --bs-btn-active-border-color: #00ac69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00ac69;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00ac69;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #00ba94;
  --bs-btn-border-color: #00ba94;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00ba94;
  --bs-btn-hover-border-color: #00ba94;
  --bs-btn-focus-shadow-rgb: 0, 186, 148;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00ba94;
  --bs-btn-active-border-color: #00ba94;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00ba94;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00ba94;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #00cfd5;
  --bs-btn-border-color: #00cfd5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00cfd5;
  --bs-btn-hover-border-color: #00cfd5;
  --bs-btn-focus-shadow-rgb: 0, 207, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00cfd5;
  --bs-btn-active-border-color: #00cfd5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00cfd5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00cfd5;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #0061f2;
  --bs-btn-border-color: #0061f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0061f2;
  --bs-btn-hover-border-color: #0061f2;
  --bs-btn-focus-shadow-rgb: 0, 97, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0061f2;
  --bs-btn-active-border-color: #0061f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0061f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0061f2;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #5800e8;
  --bs-btn-border-color: #5800e8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5800e8;
  --bs-btn-hover-border-color: #5800e8;
  --bs-btn-focus-shadow-rgb: 88, 0, 232;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5800e8;
  --bs-btn-active-border-color: #5800e8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5800e8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5800e8;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #6900c7;
  --bs-btn-border-color: #6900c7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6900c7;
  --bs-btn-hover-border-color: #6900c7;
  --bs-btn-focus-shadow-rgb: 105, 0, 199;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6900c7;
  --bs-btn-active-border-color: #6900c7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6900c7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6900c7;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #e30059;
  --bs-btn-border-color: #e30059;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e30059;
  --bs-btn-hover-border-color: #e30059;
  --bs-btn-focus-shadow-rgb: 227, 0, 89;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e30059;
  --bs-btn-active-border-color: #e30059;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e30059;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e30059;
  --bs-gradient: none;
}

.btn-outline-red-soft {
  --bs-btn-color: #f1e0e3;
  --bs-btn-border-color: #f1e0e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1e0e3;
  --bs-btn-hover-border-color: #f1e0e3;
  --bs-btn-focus-shadow-rgb: 241, 224, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f1e0e3;
  --bs-btn-active-border-color: #f1e0e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1e0e3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1e0e3;
  --bs-gradient: none;
}

.btn-outline-orange-soft {
  --bs-btn-color: #f3e7e3;
  --bs-btn-border-color: #f3e7e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f3e7e3;
  --bs-btn-hover-border-color: #f3e7e3;
  --bs-btn-focus-shadow-rgb: 243, 231, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f3e7e3;
  --bs-btn-active-border-color: #f3e7e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f3e7e3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f3e7e3;
  --bs-gradient: none;
}

.btn-outline-yellow-soft {
  --bs-btn-color: #f2eee3;
  --bs-btn-border-color: #f2eee3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2eee3;
  --bs-btn-hover-border-color: #f2eee3;
  --bs-btn-focus-shadow-rgb: 242, 238, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2eee3;
  --bs-btn-active-border-color: #f2eee3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f2eee3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2eee3;
  --bs-gradient: none;
}

.btn-outline-green-soft {
  --bs-btn-color: #daefed;
  --bs-btn-border-color: #daefed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #daefed;
  --bs-btn-hover-border-color: #daefed;
  --bs-btn-focus-shadow-rgb: 218, 239, 237;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daefed;
  --bs-btn-active-border-color: #daefed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #daefed;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #daefed;
  --bs-gradient: none;
}

.btn-outline-teal-soft {
  --bs-btn-color: #daf0f2;
  --bs-btn-border-color: #daf0f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #daf0f2;
  --bs-btn-hover-border-color: #daf0f2;
  --bs-btn-focus-shadow-rgb: 218, 240, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daf0f2;
  --bs-btn-active-border-color: #daf0f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #daf0f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #daf0f2;
  --bs-gradient: none;
}

.btn-outline-cyan-soft {
  --bs-btn-color: #daf2f8;
  --bs-btn-border-color: #daf2f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #daf2f8;
  --bs-btn-hover-border-color: #daf2f8;
  --bs-btn-focus-shadow-rgb: 218, 242, 248;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daf2f8;
  --bs-btn-active-border-color: #daf2f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #daf2f8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #daf2f8;
  --bs-gradient: none;
}

.btn-outline-blue-soft {
  --bs-btn-color: #dae7fb;
  --bs-btn-border-color: #dae7fb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dae7fb;
  --bs-btn-hover-border-color: #dae7fb;
  --bs-btn-focus-shadow-rgb: 218, 231, 251;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dae7fb;
  --bs-btn-active-border-color: #dae7fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dae7fb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dae7fb;
  --bs-gradient: none;
}

.btn-outline-indigo-soft {
  --bs-btn-color: #e3ddfa;
  --bs-btn-border-color: #e3ddfa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e3ddfa;
  --bs-btn-hover-border-color: #e3ddfa;
  --bs-btn-focus-shadow-rgb: 227, 221, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e3ddfa;
  --bs-btn-active-border-color: #e3ddfa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e3ddfa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e3ddfa;
  --bs-gradient: none;
}

.btn-outline-purple-soft {
  --bs-btn-color: #e4ddf7;
  --bs-btn-border-color: #e4ddf7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e4ddf7;
  --bs-btn-hover-border-color: #e4ddf7;
  --bs-btn-focus-shadow-rgb: 228, 221, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e4ddf7;
  --bs-btn-active-border-color: #e4ddf7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4ddf7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4ddf7;
  --bs-gradient: none;
}

.btn-outline-pink-soft {
  --bs-btn-color: #f1ddec;
  --bs-btn-border-color: #f1ddec;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1ddec;
  --bs-btn-hover-border-color: #f1ddec;
  --bs-btn-focus-shadow-rgb: 241, 221, 236;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f1ddec;
  --bs-btn-active-border-color: #f1ddec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1ddec;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1ddec;
  --bs-gradient: none;
}

.btn-outline-primary-soft {
  --bs-btn-color: #e0dee6;
  --bs-btn-border-color: #e0dee6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0dee6;
  --bs-btn-hover-border-color: #e0dee6;
  --bs-btn-focus-shadow-rgb: 224, 222, 230;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e0dee6;
  --bs-btn-active-border-color: #e0dee6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e0dee6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e0dee6;
  --bs-gradient: none;
}

.btn-outline-secondary-soft {
  --bs-btn-color: #e5e3ea;
  --bs-btn-border-color: #e5e3ea;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e3ea;
  --bs-btn-hover-border-color: #e5e3ea;
  --bs-btn-focus-shadow-rgb: 229, 227, 234;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e5e3ea;
  --bs-btn-active-border-color: #e5e3ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e5e3ea;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e5e3ea;
  --bs-gradient: none;
}

.btn-outline-success-soft {
  --bs-btn-color: #daefed;
  --bs-btn-border-color: #daefed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #daefed;
  --bs-btn-hover-border-color: #daefed;
  --bs-btn-focus-shadow-rgb: 218, 239, 237;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daefed;
  --bs-btn-active-border-color: #daefed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #daefed;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #daefed;
  --bs-gradient: none;
}

.btn-outline-info-soft {
  --bs-btn-color: #daf2f8;
  --bs-btn-border-color: #daf2f8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #daf2f8;
  --bs-btn-hover-border-color: #daf2f8;
  --bs-btn-focus-shadow-rgb: 218, 242, 248;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daf2f8;
  --bs-btn-active-border-color: #daf2f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #daf2f8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #daf2f8;
  --bs-gradient: none;
}

.btn-outline-warning-soft {
  --bs-btn-color: #f2eee3;
  --bs-btn-border-color: #f2eee3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2eee3;
  --bs-btn-hover-border-color: #f2eee3;
  --bs-btn-focus-shadow-rgb: 242, 238, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2eee3;
  --bs-btn-active-border-color: #f2eee3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f2eee3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2eee3;
  --bs-gradient: none;
}

.btn-outline-danger-soft {
  --bs-btn-color: #f1e0e3;
  --bs-btn-border-color: #f1e0e3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1e0e3;
  --bs-btn-hover-border-color: #f1e0e3;
  --bs-btn-focus-shadow-rgb: 241, 224, 227;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f1e0e3;
  --bs-btn-active-border-color: #f1e0e3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1e0e3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1e0e3;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #69707a;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 88, 44, 62;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.125rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #69707a;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #e0e5ec;
  --bs-dropdown-border-radius: 0.35rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.35rem - 1px);
  --bs-dropdown-divider-bg: #e0e5ec;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  --bs-dropdown-link-color: #212832;
  --bs-dropdown-link-hover-color: #1e242d;
  --bs-dropdown-link-hover-bg: #e0e5ec;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3A071C;
  --bs-dropdown-link-disabled-color: #a7aeb8;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #69707a;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #d4dae3;
  --bs-dropdown-bg: #6C3647;
  --bs-dropdown-border-color: #e0e5ec;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #d4dae3;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e0e5ec;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3A071C;
  --bs-dropdown-link-disabled-color: #a7aeb8;
  --bs-dropdown-header-color: #a7aeb8;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.35rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #69707a;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #d4dae3;
  --bs-nav-tabs-border-radius: 0.35rem;
  --bs-nav-tabs-link-hover-border-color: #e0e5ec #e0e5ec #d4dae3;
  --bs-nav-tabs-link-active-color: #4a515b;
  --bs-nav-tabs-link-active-bg: #f2f6fc;
  --bs-nav-tabs-link-active-border-color: #d4dae3 #d4dae3 #f2f6fc;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.35rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #3A071C;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 1rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.35rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.35rem;
  --bs-card-spacer-x: 1.35rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(33, 40, 50, 0.125);
  --bs-card-border-radius: 0.35rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0.35rem;
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1.35rem;
  --bs-card-cap-bg: rgba(33, 40, 50, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.35rem;
  --bs-accordion-inner-border-radius: calc(0.35rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23340619'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #340619;
  --bs-accordion-active-bg: #ebe6e8;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #69707a;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #69707a;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination, .dataTable-pagination ul {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #3A071C;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #d4dae3;
  --bs-pagination-border-radius: 0.35rem;
  --bs-pagination-hover-color: #570F2E;
  --bs-pagination-hover-bg: #e0e5ec;
  --bs-pagination-hover-border-color: #d4dae3;
  --bs-pagination-focus-color: #570F2E;
  --bs-pagination-focus-bg: #e0e5ec;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #3A071C;
  --bs-pagination-active-border-color: #3A071C;
  --bs-pagination-disabled-color: #69707a;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #d4dae3;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link, .dataTable-pagination a {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link, .dataTable-pagination a {
    transition: none;
  }
}
.page-link:hover, .dataTable-pagination a:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus, .dataTable-pagination a:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .dataTable-pagination a.active, .active > .page-link, .dataTable-pagination .active > a {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .dataTable-pagination a.disabled, .disabled > .page-link, .dataTable-pagination .disabled > a {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link, .page-item:not(:first-child) .dataTable-pagination a, .dataTable-pagination .page-item:not(:first-child) a, .dataTable-pagination li:not(:first-child) .page-link, .dataTable-pagination li:not(:first-child) a {
  margin-left: -1px;
}
.page-item:first-child .page-link, .page-item:first-child .dataTable-pagination a, .dataTable-pagination .page-item:first-child a, .dataTable-pagination li:first-child .page-link, .dataTable-pagination li:first-child a {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link, .page-item:last-child .dataTable-pagination a, .dataTable-pagination .page-item:last-child a, .dataTable-pagination li:last-child .page-link, .dataTable-pagination li:last-child a {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.35rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1.25rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.35rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.5625rem 1rem;
}

.alert-primary {
  --bs-alert-color: #230411;
  --bs-alert-bg: #d8cdd2;
  --bs-alert-border-color: #c4b5bb;
}
.alert-primary .alert-link {
  color: #1c030e;
}

.alert-secondary {
  --bs-alert-color: #41202b;
  --bs-alert-bg: #e2d7da;
  --bs-alert-border-color: #d3c3c8;
}
.alert-secondary .alert-link {
  color: #341a22;
}

.alert-success {
  --bs-alert-color: #00673f;
  --bs-alert-bg: #cceee1;
  --bs-alert-border-color: #b3e6d2;
}
.alert-success .alert-link {
  color: #005232;
}

.alert-info {
  --bs-alert-color: #007c80;
  --bs-alert-bg: #ccf5f7;
  --bs-alert-border-color: #b3f1f2;
}
.alert-info .alert-link {
  color: #006366;
}

.alert-warning {
  --bs-alert-color: #926100;
  --bs-alert-bg: #fdeccc;
  --bs-alert-border-color: #fce3b3;
}
.alert-warning .alert-link {
  color: #754e00;
}

.alert-danger {
  --bs-alert-color: #8b0d00;
  --bs-alert-bg: #fad0cc;
  --bs-alert-border-color: #f8b9b3;
}
.alert-danger .alert-link {
  color: #6f0a00;
}

.alert-light {
  --bs-alert-color: #919497;
  --bs-alert-bg: #fcfdfe;
  --bs-alert-border-color: #fbfcfe;
}
.alert-light .alert-link {
  color: #747679;
}

.alert-dark {
  --bs-alert-color: #14181e;
  --bs-alert-bg: #d3d4d6;
  --bs-alert-border-color: #bcbfc2;
}
.alert-dark .alert-link {
  color: #101318;
}

.alert-black {
  --bs-alert-color: black;
  --bs-alert-bg: #cccccc;
  --bs-alert-border-color: #b3b3b3;
}
.alert-black .alert-link {
  color: black;
}

.alert-white {
  --bs-alert-color: #999999;
  --bs-alert-bg: white;
  --bs-alert-border-color: white;
}
.alert-white .alert-link {
  color: #7a7a7a;
}

.alert-red {
  --bs-alert-color: #8b0d00;
  --bs-alert-bg: #fad0cc;
  --bs-alert-border-color: #f8b9b3;
}
.alert-red .alert-link {
  color: #6f0a00;
}

.alert-orange {
  --bs-alert-color: #943c00;
  --bs-alert-bg: #fde0cc;
  --bs-alert-border-color: #fdd1b3;
}
.alert-orange .alert-link {
  color: #763000;
}

.alert-yellow {
  --bs-alert-color: #926100;
  --bs-alert-bg: #fdeccc;
  --bs-alert-border-color: #fce3b3;
}
.alert-yellow .alert-link {
  color: #754e00;
}

.alert-green {
  --bs-alert-color: #00673f;
  --bs-alert-bg: #cceee1;
  --bs-alert-border-color: #b3e6d2;
}
.alert-green .alert-link {
  color: #005232;
}

.alert-teal {
  --bs-alert-color: #007059;
  --bs-alert-bg: #ccf1ea;
  --bs-alert-border-color: #b3eadf;
}
.alert-teal .alert-link {
  color: #005a47;
}

.alert-cyan {
  --bs-alert-color: #007c80;
  --bs-alert-bg: #ccf5f7;
  --bs-alert-border-color: #b3f1f2;
}
.alert-cyan .alert-link {
  color: #006366;
}

.alert-blue {
  --bs-alert-color: #003a91;
  --bs-alert-bg: #ccdffc;
  --bs-alert-border-color: #b3d0fb;
}
.alert-blue .alert-link {
  color: #002e74;
}

.alert-indigo {
  --bs-alert-color: #35008b;
  --bs-alert-bg: #deccfa;
  --bs-alert-border-color: #cdb3f8;
}
.alert-indigo .alert-link {
  color: #2a006f;
}

.alert-purple {
  --bs-alert-color: #3f0077;
  --bs-alert-bg: #e1ccf4;
  --bs-alert-border-color: #d2b3ee;
}
.alert-purple .alert-link {
  color: #32005f;
}

.alert-pink {
  --bs-alert-color: #880035;
  --bs-alert-bg: #f9ccde;
  --bs-alert-border-color: #f7b3cd;
}
.alert-pink .alert-link {
  color: #6d002a;
}

.alert-red-soft {
  --bs-alert-color: #918688;
  --bs-alert-bg: #fcf9f9;
  --bs-alert-border-color: #fbf6f7;
}
.alert-red-soft .alert-link {
  color: #746b6d;
}

.alert-orange-soft {
  --bs-alert-color: #928b88;
  --bs-alert-bg: #fdfaf9;
  --bs-alert-border-color: #fbf8f7;
}
.alert-orange-soft .alert-link {
  color: #756f6d;
}

.alert-yellow-soft {
  --bs-alert-color: #918f88;
  --bs-alert-bg: #fcfcf9;
  --bs-alert-border-color: #fbfaf7;
}
.alert-yellow-soft .alert-link {
  color: #74726d;
}

.alert-green-soft {
  --bs-alert-color: #838f8e;
  --bs-alert-bg: #f8fcfb;
  --bs-alert-border-color: #f4fafa;
}
.alert-green-soft .alert-link {
  color: #697272;
}

.alert-teal-soft {
  --bs-alert-color: #839091;
  --bs-alert-bg: #f8fcfc;
  --bs-alert-border-color: #f4fbfb;
}
.alert-teal-soft .alert-link {
  color: #697374;
}

.alert-cyan-soft {
  --bs-alert-color: #839195;
  --bs-alert-bg: #f8fcfe;
  --bs-alert-border-color: #f4fbfd;
}
.alert-cyan-soft .alert-link {
  color: #697477;
}

.alert-blue-soft {
  --bs-alert-color: #838b97;
  --bs-alert-bg: #f8fafe;
  --bs-alert-border-color: #f4f8fe;
}
.alert-blue-soft .alert-link {
  color: #696f79;
}

.alert-indigo-soft {
  --bs-alert-color: #888596;
  --bs-alert-bg: #f9f8fe;
  --bs-alert-border-color: #f7f5fe;
}
.alert-indigo-soft .alert-link {
  color: #6d6a78;
}

.alert-purple-soft {
  --bs-alert-color: #898594;
  --bs-alert-bg: #faf8fd;
  --bs-alert-border-color: #f7f5fd;
}
.alert-purple-soft .alert-link {
  color: #6e6a76;
}

.alert-pink-soft {
  --bs-alert-color: #91858e;
  --bs-alert-bg: #fcf8fb;
  --bs-alert-border-color: #fbf5f9;
}
.alert-pink-soft .alert-link {
  color: #746a72;
}

.alert-primary-soft {
  --bs-alert-color: #86858a;
  --bs-alert-bg: #f9f8fa;
  --bs-alert-border-color: #f6f5f8;
}
.alert-primary-soft .alert-link {
  color: #6b6a6e;
}

.alert-secondary-soft {
  --bs-alert-color: #89888c;
  --bs-alert-bg: #faf9fb;
  --bs-alert-border-color: #f7f7f9;
}
.alert-secondary-soft .alert-link {
  color: #6e6d70;
}

.alert-success-soft {
  --bs-alert-color: #838f8e;
  --bs-alert-bg: #f8fcfb;
  --bs-alert-border-color: #f4fafa;
}
.alert-success-soft .alert-link {
  color: #697272;
}

.alert-info-soft {
  --bs-alert-color: #839195;
  --bs-alert-bg: #f8fcfe;
  --bs-alert-border-color: #f4fbfd;
}
.alert-info-soft .alert-link {
  color: #697477;
}

.alert-warning-soft {
  --bs-alert-color: #918f88;
  --bs-alert-bg: #fcfcf9;
  --bs-alert-border-color: #fbfaf7;
}
.alert-warning-soft .alert-link {
  color: #74726d;
}

.alert-danger-soft {
  --bs-alert-color: #918688;
  --bs-alert-bg: #fcf9f9;
  --bs-alert-border-color: #fbf6f7;
}
.alert-danger-soft .alert-link {
  color: #746b6d;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e0e5ec;
  --bs-progress-border-radius: 0.35rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #3A071C;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212832;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.35rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #4a515b;
  --bs-list-group-action-hover-color: #4a515b;
  --bs-list-group-action-hover-bg: #f2f6fc;
  --bs-list-group-action-active-color: #69707a;
  --bs-list-group-action-active-bg: #e0e5ec;
  --bs-list-group-disabled-color: #69707a;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #3A071C;
  --bs-list-group-active-border-color: #3A071C;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1500px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #230411;
  background-color: #d8cdd2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #230411;
  background-color: #c2b9bd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #230411;
  border-color: #230411;
}

.list-group-item-secondary {
  color: #41202b;
  background-color: #e2d7da;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41202b;
  background-color: #cbc2c4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41202b;
  border-color: #41202b;
}

.list-group-item-success {
  color: #00673f;
  background-color: #cceee1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #00673f;
  background-color: #b8d6cb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #00673f;
  border-color: #00673f;
}

.list-group-item-info {
  color: #007c80;
  background-color: #ccf5f7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007c80;
  background-color: #b8ddde;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007c80;
  border-color: #007c80;
}

.list-group-item-warning {
  color: #926100;
  background-color: #fdeccc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #926100;
  background-color: #e4d4b8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #926100;
  border-color: #926100;
}

.list-group-item-danger {
  color: #8b0d00;
  background-color: #fad0cc;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #8b0d00;
  background-color: #e1bbb8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8b0d00;
  border-color: #8b0d00;
}

.list-group-item-light {
  color: #919497;
  background-color: #fcfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #919497;
  background-color: #e3e4e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #919497;
  border-color: #919497;
}

.list-group-item-dark {
  color: #14181e;
  background-color: #d3d4d6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #14181e;
  background-color: #bebfc1;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #14181e;
  border-color: #14181e;
}

.list-group-item-black {
  color: black;
  background-color: #cccccc;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #999999;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}

.list-group-item-red {
  color: #8b0d00;
  background-color: #fad0cc;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #8b0d00;
  background-color: #e1bbb8;
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #8b0d00;
  border-color: #8b0d00;
}

.list-group-item-orange {
  color: #943c00;
  background-color: #fde0cc;
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #943c00;
  background-color: #e4cab8;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #943c00;
  border-color: #943c00;
}

.list-group-item-yellow {
  color: #926100;
  background-color: #fdeccc;
}
.list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
  color: #926100;
  background-color: #e4d4b8;
}
.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #926100;
  border-color: #926100;
}

.list-group-item-green {
  color: #00673f;
  background-color: #cceee1;
}
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #00673f;
  background-color: #b8d6cb;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #00673f;
  border-color: #00673f;
}

.list-group-item-teal {
  color: #007059;
  background-color: #ccf1ea;
}
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: #007059;
  background-color: #b8d9d3;
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #007059;
  border-color: #007059;
}

.list-group-item-cyan {
  color: #007c80;
  background-color: #ccf5f7;
}
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: #007c80;
  background-color: #b8ddde;
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #007c80;
  border-color: #007c80;
}

.list-group-item-blue {
  color: #003a91;
  background-color: #ccdffc;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #003a91;
  background-color: #b8c9e3;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #003a91;
  border-color: #003a91;
}

.list-group-item-indigo {
  color: #35008b;
  background-color: #deccfa;
}
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #35008b;
  background-color: #c8b8e1;
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #35008b;
  border-color: #35008b;
}

.list-group-item-purple {
  color: #3f0077;
  background-color: #e1ccf4;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #3f0077;
  background-color: #cbb8dc;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #3f0077;
  border-color: #3f0077;
}

.list-group-item-pink {
  color: #880035;
  background-color: #f9ccde;
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #880035;
  background-color: #e0b8c8;
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #880035;
  border-color: #880035;
}

.list-group-item-red-soft {
  color: #918688;
  background-color: #fcf9f9;
}
.list-group-item-red-soft.list-group-item-action:hover, .list-group-item-red-soft.list-group-item-action:focus {
  color: #918688;
  background-color: #e3e0e0;
}
.list-group-item-red-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918688;
  border-color: #918688;
}

.list-group-item-orange-soft {
  color: #928b88;
  background-color: #fdfaf9;
}
.list-group-item-orange-soft.list-group-item-action:hover, .list-group-item-orange-soft.list-group-item-action:focus {
  color: #928b88;
  background-color: #e4e1e0;
}
.list-group-item-orange-soft.list-group-item-action.active {
  color: #fff;
  background-color: #928b88;
  border-color: #928b88;
}

.list-group-item-yellow-soft {
  color: #918f88;
  background-color: #fcfcf9;
}
.list-group-item-yellow-soft.list-group-item-action:hover, .list-group-item-yellow-soft.list-group-item-action:focus {
  color: #918f88;
  background-color: #e3e3e0;
}
.list-group-item-yellow-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918f88;
  border-color: #918f88;
}

.list-group-item-green-soft {
  color: #838f8e;
  background-color: #f8fcfb;
}
.list-group-item-green-soft.list-group-item-action:hover, .list-group-item-green-soft.list-group-item-action:focus {
  color: #838f8e;
  background-color: #dfe3e2;
}
.list-group-item-green-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838f8e;
  border-color: #838f8e;
}

.list-group-item-teal-soft {
  color: #839091;
  background-color: #f8fcfc;
}
.list-group-item-teal-soft.list-group-item-action:hover, .list-group-item-teal-soft.list-group-item-action:focus {
  color: #839091;
  background-color: #dfe3e3;
}
.list-group-item-teal-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839091;
  border-color: #839091;
}

.list-group-item-cyan-soft {
  color: #839195;
  background-color: #f8fcfe;
}
.list-group-item-cyan-soft.list-group-item-action:hover, .list-group-item-cyan-soft.list-group-item-action:focus {
  color: #839195;
  background-color: #dfe3e5;
}
.list-group-item-cyan-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839195;
  border-color: #839195;
}

.list-group-item-blue-soft {
  color: #838b97;
  background-color: #f8fafe;
}
.list-group-item-blue-soft.list-group-item-action:hover, .list-group-item-blue-soft.list-group-item-action:focus {
  color: #838b97;
  background-color: #dfe1e5;
}
.list-group-item-blue-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838b97;
  border-color: #838b97;
}

.list-group-item-indigo-soft {
  color: #888596;
  background-color: #f9f8fe;
}
.list-group-item-indigo-soft.list-group-item-action:hover, .list-group-item-indigo-soft.list-group-item-action:focus {
  color: #888596;
  background-color: #e0dfe5;
}
.list-group-item-indigo-soft.list-group-item-action.active {
  color: #fff;
  background-color: #888596;
  border-color: #888596;
}

.list-group-item-purple-soft {
  color: #898594;
  background-color: #faf8fd;
}
.list-group-item-purple-soft.list-group-item-action:hover, .list-group-item-purple-soft.list-group-item-action:focus {
  color: #898594;
  background-color: #e1dfe4;
}
.list-group-item-purple-soft.list-group-item-action.active {
  color: #fff;
  background-color: #898594;
  border-color: #898594;
}

.list-group-item-pink-soft {
  color: #91858e;
  background-color: #fcf8fb;
}
.list-group-item-pink-soft.list-group-item-action:hover, .list-group-item-pink-soft.list-group-item-action:focus {
  color: #91858e;
  background-color: #e3dfe2;
}
.list-group-item-pink-soft.list-group-item-action.active {
  color: #fff;
  background-color: #91858e;
  border-color: #91858e;
}

.list-group-item-primary-soft {
  color: #86858a;
  background-color: #f9f8fa;
}
.list-group-item-primary-soft.list-group-item-action:hover, .list-group-item-primary-soft.list-group-item-action:focus {
  color: #86858a;
  background-color: #e0dfe1;
}
.list-group-item-primary-soft.list-group-item-action.active {
  color: #fff;
  background-color: #86858a;
  border-color: #86858a;
}

.list-group-item-secondary-soft {
  color: #89888c;
  background-color: #faf9fb;
}
.list-group-item-secondary-soft.list-group-item-action:hover, .list-group-item-secondary-soft.list-group-item-action:focus {
  color: #89888c;
  background-color: #e1e0e2;
}
.list-group-item-secondary-soft.list-group-item-action.active {
  color: #fff;
  background-color: #89888c;
  border-color: #89888c;
}

.list-group-item-success-soft {
  color: #838f8e;
  background-color: #f8fcfb;
}
.list-group-item-success-soft.list-group-item-action:hover, .list-group-item-success-soft.list-group-item-action:focus {
  color: #838f8e;
  background-color: #dfe3e2;
}
.list-group-item-success-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838f8e;
  border-color: #838f8e;
}

.list-group-item-info-soft {
  color: #839195;
  background-color: #f8fcfe;
}
.list-group-item-info-soft.list-group-item-action:hover, .list-group-item-info-soft.list-group-item-action:focus {
  color: #839195;
  background-color: #dfe3e5;
}
.list-group-item-info-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839195;
  border-color: #839195;
}

.list-group-item-warning-soft {
  color: #918f88;
  background-color: #fcfcf9;
}
.list-group-item-warning-soft.list-group-item-action:hover, .list-group-item-warning-soft.list-group-item-action:focus {
  color: #918f88;
  background-color: #e3e3e0;
}
.list-group-item-warning-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918f88;
  border-color: #918f88;
}

.list-group-item-danger-soft {
  color: #918688;
  background-color: #fcf9f9;
}
.list-group-item-danger-soft.list-group-item-action:hover, .list-group-item-danger-soft.list-group-item-action:focus {
  color: #918688;
  background-color: #e3e0e0;
}
.list-group-item-danger-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918688;
  border-color: #918688;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.35rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.35rem;
  --bs-toast-box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  --bs-toast-header-color: #69707a;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1499.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.35rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Qatar2022", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #fff;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #69707a;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Qatar2022", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1499.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1499.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1499.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1500px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(58, 7, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 54, 71, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(0, 172, 105, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(0, 207, 213, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(244, 161, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(232, 21, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(242, 246, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 40, 50, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #fff !important;
  background-color: RGBA(232, 21, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #fff !important;
  background-color: RGBA(247, 100, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #fff !important;
  background-color: RGBA(244, 161, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #fff !important;
  background-color: RGBA(0, 172, 105, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #fff !important;
  background-color: RGBA(0, 186, 148, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #fff !important;
  background-color: RGBA(0, 207, 213, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(0, 97, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #fff !important;
  background-color: RGBA(88, 0, 232, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #fff !important;
  background-color: RGBA(105, 0, 199, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #fff !important;
  background-color: RGBA(227, 0, 89, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-soft {
  color: #000 !important;
  background-color: RGBA(241, 224, 227, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-soft {
  color: #000 !important;
  background-color: RGBA(243, 231, 227, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-soft {
  color: #000 !important;
  background-color: RGBA(242, 238, 227, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-soft {
  color: #000 !important;
  background-color: RGBA(218, 239, 237, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-soft {
  color: #000 !important;
  background-color: RGBA(218, 240, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-soft {
  color: #000 !important;
  background-color: RGBA(218, 242, 248, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-soft {
  color: #000 !important;
  background-color: RGBA(218, 231, 251, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-soft {
  color: #000 !important;
  background-color: RGBA(227, 221, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-soft {
  color: #000 !important;
  background-color: RGBA(228, 221, 247, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-soft {
  color: #000 !important;
  background-color: RGBA(241, 221, 236, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-soft {
  color: #000 !important;
  background-color: RGBA(224, 222, 230, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-soft {
  color: #000 !important;
  background-color: RGBA(229, 227, 234, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success-soft {
  color: #000 !important;
  background-color: RGBA(218, 239, 237, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info-soft {
  color: #000 !important;
  background-color: RGBA(218, 242, 248, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning-soft {
  color: #000 !important;
  background-color: RGBA(242, 238, 227, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger-soft {
  color: #000 !important;
  background-color: RGBA(241, 224, 227, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #3A071C !important;
}
.link-primary:hover, .link-primary:focus {
  color: #2e0616 !important;
}

.link-secondary {
  color: #6C3647 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #562b39 !important;
}

.link-success {
  color: #00ac69 !important;
}
.link-success:hover, .link-success:focus {
  color: #008a54 !important;
}

.link-info {
  color: #00cfd5 !important;
}
.link-info:hover, .link-info:focus {
  color: #00a6aa !important;
}

.link-warning {
  color: #f4a100 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #c38100 !important;
}

.link-danger {
  color: #e81500 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #ba1100 !important;
}

.link-light {
  color: #f2f6fc !important;
}
.link-light:hover, .link-light:focus {
  color: #f5f8fd !important;
}

.link-dark {
  color: #212832 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a2028 !important;
}

.link-black {
  color: #000 !important;
}
.link-black:hover, .link-black:focus {
  color: black !important;
}

.link-white {
  color: #fff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-red {
  color: #e81500 !important;
}
.link-red:hover, .link-red:focus {
  color: #ba1100 !important;
}

.link-orange {
  color: #f76400 !important;
}
.link-orange:hover, .link-orange:focus {
  color: #c65000 !important;
}

.link-yellow {
  color: #f4a100 !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: #c38100 !important;
}

.link-green {
  color: #00ac69 !important;
}
.link-green:hover, .link-green:focus {
  color: #008a54 !important;
}

.link-teal {
  color: #00ba94 !important;
}
.link-teal:hover, .link-teal:focus {
  color: #009576 !important;
}

.link-cyan {
  color: #00cfd5 !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: #00a6aa !important;
}

.link-blue {
  color: #0061f2 !important;
}
.link-blue:hover, .link-blue:focus {
  color: #004ec2 !important;
}

.link-indigo {
  color: #5800e8 !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: #4600ba !important;
}

.link-purple {
  color: #6900c7 !important;
}
.link-purple:hover, .link-purple:focus {
  color: #54009f !important;
}

.link-pink {
  color: #e30059 !important;
}
.link-pink:hover, .link-pink:focus {
  color: #b60047 !important;
}

.link-red-soft {
  color: #f1e0e3 !important;
}
.link-red-soft:hover, .link-red-soft:focus {
  color: #f4e6e9 !important;
}

.link-orange-soft {
  color: #f3e7e3 !important;
}
.link-orange-soft:hover, .link-orange-soft:focus {
  color: #f5ece9 !important;
}

.link-yellow-soft {
  color: #f2eee3 !important;
}
.link-yellow-soft:hover, .link-yellow-soft:focus {
  color: #f5f1e9 !important;
}

.link-green-soft {
  color: #daefed !important;
}
.link-green-soft:hover, .link-green-soft:focus {
  color: #e1f2f1 !important;
}

.link-teal-soft {
  color: #daf0f2 !important;
}
.link-teal-soft:hover, .link-teal-soft:focus {
  color: #e1f3f5 !important;
}

.link-cyan-soft {
  color: #daf2f8 !important;
}
.link-cyan-soft:hover, .link-cyan-soft:focus {
  color: #e1f5f9 !important;
}

.link-blue-soft {
  color: #dae7fb !important;
}
.link-blue-soft:hover, .link-blue-soft:focus {
  color: #e1ecfc !important;
}

.link-indigo-soft {
  color: #e3ddfa !important;
}
.link-indigo-soft:hover, .link-indigo-soft:focus {
  color: #e9e4fb !important;
}

.link-purple-soft {
  color: #e4ddf7 !important;
}
.link-purple-soft:hover, .link-purple-soft:focus {
  color: #e9e4f9 !important;
}

.link-pink-soft {
  color: #f1ddec !important;
}
.link-pink-soft:hover, .link-pink-soft:focus {
  color: #f4e4f0 !important;
}

.link-primary-soft {
  color: #e0dee6 !important;
}
.link-primary-soft:hover, .link-primary-soft:focus {
  color: #e6e5eb !important;
}

.link-secondary-soft {
  color: #e5e3ea !important;
}
.link-secondary-soft:hover, .link-secondary-soft:focus {
  color: #eae9ee !important;
}

.link-success-soft {
  color: #daefed !important;
}
.link-success-soft:hover, .link-success-soft:focus {
  color: #e1f2f1 !important;
}

.link-info-soft {
  color: #daf2f8 !important;
}
.link-info-soft:hover, .link-info-soft:focus {
  color: #e1f5f9 !important;
}

.link-warning-soft {
  color: #f2eee3 !important;
}
.link-warning-soft:hover, .link-warning-soft:focus {
  color: #f5f1e9 !important;
}

.link-danger-soft {
  color: #f1e0e3 !important;
}
.link-danger-soft:hover, .link-danger-soft:focus {
  color: #f4e6e9 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top, .nav-fixed #layoutSidenav #layoutSidenav_nav, .nav-fixed .topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top, .nav-sticky {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1500px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-red-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-green-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-success-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-info-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-warning-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-danger-soft {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-soft-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.m-10 {
  margin: 6rem !important;
}

.m-15 {
  margin: 9rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-10 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-15 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-15 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mt-10 {
  margin-top: 6rem !important;
}

.mt-15 {
  margin-top: 9rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2.5rem !important;
}

.me-10 {
  margin-right: 6rem !important;
}

.me-15 {
  margin-right: 9rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 6rem !important;
}

.mb-15 {
  margin-bottom: 9rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2.5rem !important;
}

.ms-10 {
  margin-left: 6rem !important;
}

.ms-15 {
  margin-left: 9rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2.5rem !important;
}

.m-n10 {
  margin: -6rem !important;
}

.m-n15 {
  margin: -9rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n10 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n15 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n10 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n15 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2.5rem !important;
}

.mt-n10 {
  margin-top: -6rem !important;
}

.mt-n15 {
  margin-top: -9rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2.5rem !important;
}

.me-n10 {
  margin-right: -6rem !important;
}

.me-n15 {
  margin-right: -9rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2.5rem !important;
}

.mb-n10 {
  margin-bottom: -6rem !important;
}

.mb-n15 {
  margin-bottom: -9rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2.5rem !important;
}

.ms-n10 {
  margin-left: -6rem !important;
}

.ms-n15 {
  margin-left: -9rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.p-10 {
  padding: 6rem !important;
}

.p-15 {
  padding: 9rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-10 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-15 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-10 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-15 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pt-10 {
  padding-top: 6rem !important;
}

.pt-15 {
  padding-top: 9rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2.5rem !important;
}

.pe-10 {
  padding-right: 6rem !important;
}

.pe-15 {
  padding-right: 9rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 6rem !important;
}

.pb-15 {
  padding-bottom: 9rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2.5rem !important;
}

.ps-10 {
  padding-left: 6rem !important;
}

.ps-15 {
  padding-left: 9rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2.5rem !important;
}

.gap-10 {
  gap: 6rem !important;
}

.gap-15 {
  gap: 9rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-2 {
  font-size: calc(1.265rem + 0.18vw) !important;
}

.fs-3 {
  font-size: calc(1.255rem + 0.06vw) !important;
}

.fs-4 {
  font-size: 1.2rem !important;
}

.fs-5 {
  font-size: 1.1rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-red-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-green-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-success-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-info-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-soft {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-soft-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #a7aeb8 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset, .footer-admin a {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-soft {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-soft-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2.5rem !important;
  }

  .m-sm-10 {
    margin: 6rem !important;
  }

  .m-sm-15 {
    margin: 9rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-10 {
    margin-top: 6rem !important;
  }

  .mt-sm-15 {
    margin-top: 9rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2.5rem !important;
  }

  .me-sm-10 {
    margin-right: 6rem !important;
  }

  .me-sm-15 {
    margin-right: 9rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-10 {
    margin-left: 6rem !important;
  }

  .ms-sm-15 {
    margin-left: 9rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2.5rem !important;
  }

  .m-sm-n10 {
    margin: -6rem !important;
  }

  .m-sm-n15 {
    margin: -9rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n10 {
    margin-top: -6rem !important;
  }

  .mt-sm-n15 {
    margin-top: -9rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n10 {
    margin-right: -6rem !important;
  }

  .me-sm-n15 {
    margin-right: -9rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -9rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n10 {
    margin-left: -6rem !important;
  }

  .ms-sm-n15 {
    margin-left: -9rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2.5rem !important;
  }

  .p-sm-10 {
    padding: 6rem !important;
  }

  .p-sm-15 {
    padding: 9rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-10 {
    padding-top: 6rem !important;
  }

  .pt-sm-15 {
    padding-top: 9rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-10 {
    padding-right: 6rem !important;
  }

  .pe-sm-15 {
    padding-right: 9rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 9rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-10 {
    padding-left: 6rem !important;
  }

  .ps-sm-15 {
    padding-left: 9rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2.5rem !important;
  }

  .gap-sm-10 {
    gap: 6rem !important;
  }

  .gap-sm-15 {
    gap: 9rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2.5rem !important;
  }

  .m-md-10 {
    margin: 6rem !important;
  }

  .m-md-15 {
    margin: 9rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2.5rem !important;
  }

  .mt-md-10 {
    margin-top: 6rem !important;
  }

  .mt-md-15 {
    margin-top: 9rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2.5rem !important;
  }

  .me-md-10 {
    margin-right: 6rem !important;
  }

  .me-md-15 {
    margin-right: 9rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-10 {
    margin-bottom: 6rem !important;
  }

  .mb-md-15 {
    margin-bottom: 9rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2.5rem !important;
  }

  .ms-md-10 {
    margin-left: 6rem !important;
  }

  .ms-md-15 {
    margin-left: 9rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2.5rem !important;
  }

  .m-md-n10 {
    margin: -6rem !important;
  }

  .m-md-n15 {
    margin: -9rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n10 {
    margin-top: -6rem !important;
  }

  .mt-md-n15 {
    margin-top: -9rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2.5rem !important;
  }

  .me-md-n10 {
    margin-right: -6rem !important;
  }

  .me-md-n15 {
    margin-right: -9rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -9rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n10 {
    margin-left: -6rem !important;
  }

  .ms-md-n15 {
    margin-left: -9rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2.5rem !important;
  }

  .p-md-10 {
    padding: 6rem !important;
  }

  .p-md-15 {
    padding: 9rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2.5rem !important;
  }

  .pt-md-10 {
    padding-top: 6rem !important;
  }

  .pt-md-15 {
    padding-top: 9rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2.5rem !important;
  }

  .pe-md-10 {
    padding-right: 6rem !important;
  }

  .pe-md-15 {
    padding-right: 9rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-10 {
    padding-bottom: 6rem !important;
  }

  .pb-md-15 {
    padding-bottom: 9rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2.5rem !important;
  }

  .ps-md-10 {
    padding-left: 6rem !important;
  }

  .ps-md-15 {
    padding-left: 9rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2.5rem !important;
  }

  .gap-md-10 {
    gap: 6rem !important;
  }

  .gap-md-15 {
    gap: 9rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2.5rem !important;
  }

  .m-lg-10 {
    margin: 6rem !important;
  }

  .m-lg-15 {
    margin: 9rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-10 {
    margin-top: 6rem !important;
  }

  .mt-lg-15 {
    margin-top: 9rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2.5rem !important;
  }

  .me-lg-10 {
    margin-right: 6rem !important;
  }

  .me-lg-15 {
    margin-right: 9rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-10 {
    margin-left: 6rem !important;
  }

  .ms-lg-15 {
    margin-left: 9rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2.5rem !important;
  }

  .m-lg-n10 {
    margin: -6rem !important;
  }

  .m-lg-n15 {
    margin: -9rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n10 {
    margin-top: -6rem !important;
  }

  .mt-lg-n15 {
    margin-top: -9rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n10 {
    margin-right: -6rem !important;
  }

  .me-lg-n15 {
    margin-right: -9rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -9rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n10 {
    margin-left: -6rem !important;
  }

  .ms-lg-n15 {
    margin-left: -9rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2.5rem !important;
  }

  .p-lg-10 {
    padding: 6rem !important;
  }

  .p-lg-15 {
    padding: 9rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-10 {
    padding-top: 6rem !important;
  }

  .pt-lg-15 {
    padding-top: 9rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-10 {
    padding-right: 6rem !important;
  }

  .pe-lg-15 {
    padding-right: 9rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 9rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-10 {
    padding-left: 6rem !important;
  }

  .ps-lg-15 {
    padding-left: 9rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2.5rem !important;
  }

  .gap-lg-10 {
    gap: 6rem !important;
  }

  .gap-lg-15 {
    gap: 9rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2.5rem !important;
  }

  .m-xl-10 {
    margin: 6rem !important;
  }

  .m-xl-15 {
    margin: 9rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-10 {
    margin-top: 6rem !important;
  }

  .mt-xl-15 {
    margin-top: 9rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2.5rem !important;
  }

  .me-xl-10 {
    margin-right: 6rem !important;
  }

  .me-xl-15 {
    margin-right: 9rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-10 {
    margin-left: 6rem !important;
  }

  .ms-xl-15 {
    margin-left: 9rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2.5rem !important;
  }

  .m-xl-n10 {
    margin: -6rem !important;
  }

  .m-xl-n15 {
    margin: -9rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n10 {
    margin-top: -6rem !important;
  }

  .mt-xl-n15 {
    margin-top: -9rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n10 {
    margin-right: -6rem !important;
  }

  .me-xl-n15 {
    margin-right: -9rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -9rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n10 {
    margin-left: -6rem !important;
  }

  .ms-xl-n15 {
    margin-left: -9rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2.5rem !important;
  }

  .p-xl-10 {
    padding: 6rem !important;
  }

  .p-xl-15 {
    padding: 9rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-10 {
    padding-top: 6rem !important;
  }

  .pt-xl-15 {
    padding-top: 9rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-10 {
    padding-right: 6rem !important;
  }

  .pe-xl-15 {
    padding-right: 9rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 9rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-10 {
    padding-left: 6rem !important;
  }

  .ps-xl-15 {
    padding-left: 9rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2.5rem !important;
  }

  .gap-xl-10 {
    gap: 6rem !important;
  }

  .gap-xl-15 {
    gap: 9rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2.5rem !important;
  }

  .m-xxl-10 {
    margin: 6rem !important;
  }

  .m-xxl-15 {
    margin: 9rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-10 {
    margin-top: 6rem !important;
  }

  .mt-xxl-15 {
    margin-top: 9rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-10 {
    margin-right: 6rem !important;
  }

  .me-xxl-15 {
    margin-right: 9rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-10 {
    margin-left: 6rem !important;
  }

  .ms-xxl-15 {
    margin-left: 9rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2.5rem !important;
  }

  .m-xxl-n10 {
    margin: -6rem !important;
  }

  .m-xxl-n15 {
    margin: -9rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -9rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n10 {
    margin-right: -6rem !important;
  }

  .me-xxl-n15 {
    margin-right: -9rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -9rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -9rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2.5rem !important;
  }

  .p-xxl-10 {
    padding: 6rem !important;
  }

  .p-xxl-15 {
    padding: 9rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-10 {
    padding-top: 6rem !important;
  }

  .pt-xxl-15 {
    padding-top: 9rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-10 {
    padding-right: 6rem !important;
  }

  .pe-xxl-15 {
    padding-right: 9rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 9rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-10 {
    padding-left: 6rem !important;
  }

  .ps-xxl-15 {
    padding-left: 9rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2.5rem !important;
  }

  .gap-xxl-10 {
    gap: 6rem !important;
  }

  .gap-xxl-15 {
    gap: 9rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.5rem !important;
  }

  .fs-2 {
    font-size: 1.4rem !important;
  }

  .fs-3 {
    font-size: 1.3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
}

@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Thin.otf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-ThinItalic.otf");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-ExtraLight.otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-ExtraLightItalic.otf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-RegularItalic.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-SemiBoldItalic.otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-ExtraBoldItalic.otf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Black.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-BlackItalic.otf");
  font-weight: 800;
  font-style: italic;
}
#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}

#layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#layoutError #layoutError_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutError #layoutError_footer {
  min-width: 0;
}

.img-error {
  max-width: 20rem;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 0.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.animated--fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 300ms;
  animation-timing-function: margin cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}
.animated--fade-in-up.dropdown-menu {
  margin-top: 0;
  top: 0.125rem !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated--fade-in {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.bg-gradient-primary-to-secondary {
  background-color: #3A071C !important;
  background-image: linear-gradient(135deg, #3A071C 0%, rgba(108, 54, 71, 0.8) 100%) !important;
}

.bg-transparent-light {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-transparent-dark {
  color: rgba(33, 40, 50, 0.5) !important;
  background-color: rgba(33, 40, 50, 0.8) !important;
}

.bg-gray-100 {
  background-color: #f2f6fc !important;
}

.bg-gray-200 {
  background-color: #e0e5ec !important;
}

.bg-gray-300 {
  background-color: #d4dae3 !important;
}

.bg-gray-400 {
  background-color: #c5ccd6 !important;
}

.bg-gray-500 {
  background-color: #a7aeb8 !important;
}

.bg-gray-600 {
  background-color: #69707a !important;
}

.bg-gray-700 {
  background-color: #4a515b !important;
}

.bg-gray-800 {
  background-color: #363d47 !important;
}

.bg-gray-900 {
  background-color: #212832 !important;
}

.bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.bg-img-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-img-repeat {
  background-position: top left;
  background-repeat: repeat;
}

.overlay {
  position: relative;
}
.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}

.overlay-primary:before {
  background-color: #3A071C !important;
}

.overlay-secondary:before {
  background-color: #6C3647 !important;
}

.overlay-success:before {
  background-color: #00ac69 !important;
}

.overlay-info:before {
  background-color: #00cfd5 !important;
}

.overlay-warning:before {
  background-color: #f4a100 !important;
}

.overlay-danger:before {
  background-color: #e81500 !important;
}

.overlay-light:before {
  background-color: #f2f6fc !important;
}

.overlay-dark:before {
  background-color: #212832 !important;
}

.overlay-black:before {
  background-color: #000 !important;
}

.overlay-white:before {
  background-color: #fff !important;
}

.overlay-red:before {
  background-color: #e81500 !important;
}

.overlay-orange:before {
  background-color: #f76400 !important;
}

.overlay-yellow:before {
  background-color: #f4a100 !important;
}

.overlay-green:before {
  background-color: #00ac69 !important;
}

.overlay-teal:before {
  background-color: #00ba94 !important;
}

.overlay-cyan:before {
  background-color: #00cfd5 !important;
}

.overlay-blue:before {
  background-color: #0061f2 !important;
}

.overlay-indigo:before {
  background-color: #5800e8 !important;
}

.overlay-purple:before {
  background-color: #6900c7 !important;
}

.overlay-pink:before {
  background-color: #e30059 !important;
}

.overlay-red-soft:before {
  background-color: #f1e0e3 !important;
}

.overlay-orange-soft:before {
  background-color: #f3e7e3 !important;
}

.overlay-yellow-soft:before {
  background-color: #f2eee3 !important;
}

.overlay-green-soft:before {
  background-color: #daefed !important;
}

.overlay-teal-soft:before {
  background-color: #daf0f2 !important;
}

.overlay-cyan-soft:before {
  background-color: #daf2f8 !important;
}

.overlay-blue-soft:before {
  background-color: #dae7fb !important;
}

.overlay-indigo-soft:before {
  background-color: #e3ddfa !important;
}

.overlay-purple-soft:before {
  background-color: #e4ddf7 !important;
}

.overlay-pink-soft:before {
  background-color: #f1ddec !important;
}

.overlay-primary-soft:before {
  background-color: #e0dee6 !important;
}

.overlay-secondary-soft:before {
  background-color: #e5e3ea !important;
}

.overlay-success-soft:before {
  background-color: #daefed !important;
}

.overlay-info-soft:before {
  background-color: #daf2f8 !important;
}

.overlay-warning-soft:before {
  background-color: #f2eee3 !important;
}

.overlay-danger-soft:before {
  background-color: #f1e0e3 !important;
}

.overlay-10:before {
  opacity: 0.1;
}

.overlay-20:before {
  opacity: 0.2;
}

.overlay-30:before {
  opacity: 0.3;
}

.overlay-40:before {
  opacity: 0.4;
}

.overlay-50:before {
  opacity: 0.5;
}

.overlay-60:before {
  opacity: 0.6;
}

.overlay-70:before {
  opacity: 0.7;
}

.overlay-80:before {
  opacity: 0.8;
}

.overlay-90:before {
  opacity: 0.9;
}

.border-lg {
  border-width: 0.25rem !important;
}

.border-top-lg {
  border-top-width: 0.25rem !important;
}

.border-end-lg {
  border-right-width: 0.25rem !important;
}

.border-bottom-lg {
  border-bottom-width: 0.25rem !important;
}

.border-start-lg {
  border-left-width: 0.25rem !important;
}

.border-primary {
  border-color: #3A071C !important;
}

.border-secondary {
  border-color: #6C3647 !important;
}

.border-success {
  border-color: #00ac69 !important;
}

.border-info {
  border-color: #00cfd5 !important;
}

.border-warning {
  border-color: #f4a100 !important;
}

.border-danger {
  border-color: #e81500 !important;
}

.border-light {
  border-color: #f2f6fc !important;
}

.border-dark {
  border-color: #212832 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-red {
  border-color: #e81500 !important;
}

.border-orange {
  border-color: #f76400 !important;
}

.border-yellow {
  border-color: #f4a100 !important;
}

.border-green {
  border-color: #00ac69 !important;
}

.border-teal {
  border-color: #00ba94 !important;
}

.border-cyan {
  border-color: #00cfd5 !important;
}

.border-blue {
  border-color: #0061f2 !important;
}

.border-indigo {
  border-color: #5800e8 !important;
}

.border-purple {
  border-color: #6900c7 !important;
}

.border-pink {
  border-color: #e30059 !important;
}

.border-red-soft {
  border-color: #f1e0e3 !important;
}

.border-orange-soft {
  border-color: #f3e7e3 !important;
}

.border-yellow-soft {
  border-color: #f2eee3 !important;
}

.border-green-soft {
  border-color: #daefed !important;
}

.border-teal-soft {
  border-color: #daf0f2 !important;
}

.border-cyan-soft {
  border-color: #daf2f8 !important;
}

.border-blue-soft {
  border-color: #dae7fb !important;
}

.border-indigo-soft {
  border-color: #e3ddfa !important;
}

.border-purple-soft {
  border-color: #e4ddf7 !important;
}

.border-pink-soft {
  border-color: #f1ddec !important;
}

.border-primary-soft {
  border-color: #e0dee6 !important;
}

.border-secondary-soft {
  border-color: #e5e3ea !important;
}

.border-success-soft {
  border-color: #daefed !important;
}

.border-info-soft {
  border-color: #daf2f8 !important;
}

.border-warning-soft {
  border-color: #f2eee3 !important;
}

.border-danger-soft {
  border-color: #f1e0e3 !important;
}

.border-start-primary {
  border-left-color: #3A071C !important;
}

.border-start-secondary {
  border-left-color: #6C3647 !important;
}

.border-start-success {
  border-left-color: #00ac69 !important;
}

.border-start-info {
  border-left-color: #00cfd5 !important;
}

.border-start-warning {
  border-left-color: #f4a100 !important;
}

.border-start-danger {
  border-left-color: #e81500 !important;
}

.border-start-light {
  border-left-color: #f2f6fc !important;
}

.border-start-dark {
  border-left-color: #212832 !important;
}

.border-start-black {
  border-left-color: #000 !important;
}

.border-start-white {
  border-left-color: #fff !important;
}

.border-start-red {
  border-left-color: #e81500 !important;
}

.border-start-orange {
  border-left-color: #f76400 !important;
}

.border-start-yellow {
  border-left-color: #f4a100 !important;
}

.border-start-green {
  border-left-color: #00ac69 !important;
}

.border-start-teal {
  border-left-color: #00ba94 !important;
}

.border-start-cyan {
  border-left-color: #00cfd5 !important;
}

.border-start-blue {
  border-left-color: #0061f2 !important;
}

.border-start-indigo {
  border-left-color: #5800e8 !important;
}

.border-start-purple {
  border-left-color: #6900c7 !important;
}

.border-start-pink {
  border-left-color: #e30059 !important;
}

.border-start-red-soft {
  border-left-color: #f1e0e3 !important;
}

.border-start-orange-soft {
  border-left-color: #f3e7e3 !important;
}

.border-start-yellow-soft {
  border-left-color: #f2eee3 !important;
}

.border-start-green-soft {
  border-left-color: #daefed !important;
}

.border-start-teal-soft {
  border-left-color: #daf0f2 !important;
}

.border-start-cyan-soft {
  border-left-color: #daf2f8 !important;
}

.border-start-blue-soft {
  border-left-color: #dae7fb !important;
}

.border-start-indigo-soft {
  border-left-color: #e3ddfa !important;
}

.border-start-purple-soft {
  border-left-color: #e4ddf7 !important;
}

.border-start-pink-soft {
  border-left-color: #f1ddec !important;
}

.border-start-primary-soft {
  border-left-color: #e0dee6 !important;
}

.border-start-secondary-soft {
  border-left-color: #e5e3ea !important;
}

.border-start-success-soft {
  border-left-color: #daefed !important;
}

.border-start-info-soft {
  border-left-color: #daf2f8 !important;
}

.border-start-warning-soft {
  border-left-color: #f2eee3 !important;
}

.border-start-danger-soft {
  border-left-color: #f1e0e3 !important;
}

.border-end-primary {
  border-right-color: #3A071C !important;
}

.border-end-secondary {
  border-right-color: #6C3647 !important;
}

.border-end-success {
  border-right-color: #00ac69 !important;
}

.border-end-info {
  border-right-color: #00cfd5 !important;
}

.border-end-warning {
  border-right-color: #f4a100 !important;
}

.border-end-danger {
  border-right-color: #e81500 !important;
}

.border-end-light {
  border-right-color: #f2f6fc !important;
}

.border-end-dark {
  border-right-color: #212832 !important;
}

.border-end-black {
  border-right-color: #000 !important;
}

.border-end-white {
  border-right-color: #fff !important;
}

.border-end-red {
  border-right-color: #e81500 !important;
}

.border-end-orange {
  border-right-color: #f76400 !important;
}

.border-end-yellow {
  border-right-color: #f4a100 !important;
}

.border-end-green {
  border-right-color: #00ac69 !important;
}

.border-end-teal {
  border-right-color: #00ba94 !important;
}

.border-end-cyan {
  border-right-color: #00cfd5 !important;
}

.border-end-blue {
  border-right-color: #0061f2 !important;
}

.border-end-indigo {
  border-right-color: #5800e8 !important;
}

.border-end-purple {
  border-right-color: #6900c7 !important;
}

.border-end-pink {
  border-right-color: #e30059 !important;
}

.border-end-red-soft {
  border-right-color: #f1e0e3 !important;
}

.border-end-orange-soft {
  border-right-color: #f3e7e3 !important;
}

.border-end-yellow-soft {
  border-right-color: #f2eee3 !important;
}

.border-end-green-soft {
  border-right-color: #daefed !important;
}

.border-end-teal-soft {
  border-right-color: #daf0f2 !important;
}

.border-end-cyan-soft {
  border-right-color: #daf2f8 !important;
}

.border-end-blue-soft {
  border-right-color: #dae7fb !important;
}

.border-end-indigo-soft {
  border-right-color: #e3ddfa !important;
}

.border-end-purple-soft {
  border-right-color: #e4ddf7 !important;
}

.border-end-pink-soft {
  border-right-color: #f1ddec !important;
}

.border-end-primary-soft {
  border-right-color: #e0dee6 !important;
}

.border-end-secondary-soft {
  border-right-color: #e5e3ea !important;
}

.border-end-success-soft {
  border-right-color: #daefed !important;
}

.border-end-info-soft {
  border-right-color: #daf2f8 !important;
}

.border-end-warning-soft {
  border-right-color: #f2eee3 !important;
}

.border-end-danger-soft {
  border-right-color: #f1e0e3 !important;
}

.border-top-primary {
  border-top-color: #3A071C !important;
}

.border-top-secondary {
  border-top-color: #6C3647 !important;
}

.border-top-success {
  border-top-color: #00ac69 !important;
}

.border-top-info {
  border-top-color: #00cfd5 !important;
}

.border-top-warning {
  border-top-color: #f4a100 !important;
}

.border-top-danger {
  border-top-color: #e81500 !important;
}

.border-top-light {
  border-top-color: #f2f6fc !important;
}

.border-top-dark {
  border-top-color: #212832 !important;
}

.border-top-black {
  border-top-color: #000 !important;
}

.border-top-white {
  border-top-color: #fff !important;
}

.border-top-red {
  border-top-color: #e81500 !important;
}

.border-top-orange {
  border-top-color: #f76400 !important;
}

.border-top-yellow {
  border-top-color: #f4a100 !important;
}

.border-top-green {
  border-top-color: #00ac69 !important;
}

.border-top-teal {
  border-top-color: #00ba94 !important;
}

.border-top-cyan {
  border-top-color: #00cfd5 !important;
}

.border-top-blue {
  border-top-color: #0061f2 !important;
}

.border-top-indigo {
  border-top-color: #5800e8 !important;
}

.border-top-purple {
  border-top-color: #6900c7 !important;
}

.border-top-pink {
  border-top-color: #e30059 !important;
}

.border-top-red-soft {
  border-top-color: #f1e0e3 !important;
}

.border-top-orange-soft {
  border-top-color: #f3e7e3 !important;
}

.border-top-yellow-soft {
  border-top-color: #f2eee3 !important;
}

.border-top-green-soft {
  border-top-color: #daefed !important;
}

.border-top-teal-soft {
  border-top-color: #daf0f2 !important;
}

.border-top-cyan-soft {
  border-top-color: #daf2f8 !important;
}

.border-top-blue-soft {
  border-top-color: #dae7fb !important;
}

.border-top-indigo-soft {
  border-top-color: #e3ddfa !important;
}

.border-top-purple-soft {
  border-top-color: #e4ddf7 !important;
}

.border-top-pink-soft {
  border-top-color: #f1ddec !important;
}

.border-top-primary-soft {
  border-top-color: #e0dee6 !important;
}

.border-top-secondary-soft {
  border-top-color: #e5e3ea !important;
}

.border-top-success-soft {
  border-top-color: #daefed !important;
}

.border-top-info-soft {
  border-top-color: #daf2f8 !important;
}

.border-top-warning-soft {
  border-top-color: #f2eee3 !important;
}

.border-top-danger-soft {
  border-top-color: #f1e0e3 !important;
}

.border-bottom-primary {
  border-bottom-color: #3A071C !important;
}

.border-bottom-secondary {
  border-bottom-color: #6C3647 !important;
}

.border-bottom-success {
  border-bottom-color: #00ac69 !important;
}

.border-bottom-info {
  border-bottom-color: #00cfd5 !important;
}

.border-bottom-warning {
  border-bottom-color: #f4a100 !important;
}

.border-bottom-danger {
  border-bottom-color: #e81500 !important;
}

.border-bottom-light {
  border-bottom-color: #f2f6fc !important;
}

.border-bottom-dark {
  border-bottom-color: #212832 !important;
}

.border-bottom-black {
  border-bottom-color: #000 !important;
}

.border-bottom-white {
  border-bottom-color: #fff !important;
}

.border-bottom-red {
  border-bottom-color: #e81500 !important;
}

.border-bottom-orange {
  border-bottom-color: #f76400 !important;
}

.border-bottom-yellow {
  border-bottom-color: #f4a100 !important;
}

.border-bottom-green {
  border-bottom-color: #00ac69 !important;
}

.border-bottom-teal {
  border-bottom-color: #00ba94 !important;
}

.border-bottom-cyan {
  border-bottom-color: #00cfd5 !important;
}

.border-bottom-blue {
  border-bottom-color: #0061f2 !important;
}

.border-bottom-indigo {
  border-bottom-color: #5800e8 !important;
}

.border-bottom-purple {
  border-bottom-color: #6900c7 !important;
}

.border-bottom-pink {
  border-bottom-color: #e30059 !important;
}

.border-bottom-red-soft {
  border-bottom-color: #f1e0e3 !important;
}

.border-bottom-orange-soft {
  border-bottom-color: #f3e7e3 !important;
}

.border-bottom-yellow-soft {
  border-bottom-color: #f2eee3 !important;
}

.border-bottom-green-soft {
  border-bottom-color: #daefed !important;
}

.border-bottom-teal-soft {
  border-bottom-color: #daf0f2 !important;
}

.border-bottom-cyan-soft {
  border-bottom-color: #daf2f8 !important;
}

.border-bottom-blue-soft {
  border-bottom-color: #dae7fb !important;
}

.border-bottom-indigo-soft {
  border-bottom-color: #e3ddfa !important;
}

.border-bottom-purple-soft {
  border-bottom-color: #e4ddf7 !important;
}

.border-bottom-pink-soft {
  border-bottom-color: #f1ddec !important;
}

.border-bottom-primary-soft {
  border-bottom-color: #e0dee6 !important;
}

.border-bottom-secondary-soft {
  border-bottom-color: #e5e3ea !important;
}

.border-bottom-success-soft {
  border-bottom-color: #daefed !important;
}

.border-bottom-info-soft {
  border-bottom-color: #daf2f8 !important;
}

.border-bottom-warning-soft {
  border-bottom-color: #f2eee3 !important;
}

.border-bottom-danger-soft {
  border-bottom-color: #f1e0e3 !important;
}

.rounded-xl {
  border-radius: 1rem !important;
}

.border-gray-100 {
  border-color: #f2f6fc !important;
}

.border-gray-200 {
  border-color: #e0e5ec !important;
}

.border-gray-300 {
  border-color: #d4dae3 !important;
}

.border-gray-400 {
  border-color: #c5ccd6 !important;
}

.border-gray-500 {
  border-color: #a7aeb8 !important;
}

.border-gray-600 {
  border-color: #69707a !important;
}

.border-gray-700 {
  border-color: #4a515b !important;
}

.border-gray-800 {
  border-color: #363d47 !important;
}

.border-gray-900 {
  border-color: #212832 !important;
}

.no-caret .dropdown-toggle::after {
  display: none;
}

.pointer {
  cursor: pointer !important;
}

.no-highlight {
  user-select: none !important;
}

.lift {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.lift:hover {
  transform: translateY(-0.3333333333rem);
  box-shadow: 0 0.5rem 2rem 0 rgba(33, 40, 50, 0.25);
}
.lift:active {
  transform: none;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}

.lift-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}
.lift-sm:hover {
  transform: translateY(-0.1666666667rem);
  box-shadow: 0 0.25rem 1rem 0 rgba(33, 40, 50, 0.25);
}
.lift-sm:active {
  transform: none;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}

.card.lift {
  text-decoration: none;
  color: inherit;
}

.o-visible {
  overflow: visible !important;
}

.o-hidden {
  overflow: hidden !important;
}

.shadow-right-sm {
  box-shadow: 0.125rem 0 0.25rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-right {
  box-shadow: 0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-right-lg {
  box-shadow: 1rem 0 3rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-left-sm {
  box-shadow: -0.125rem 0 0.25rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-left {
  box-shadow: -0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}

.shadow-left-lg {
  box-shadow: -1rem 0 3rem 0 rgba(33, 40, 50, 0.15) !important;
}

.content-skewed {
  perspective: 1500px !important;
  transform-style: preserve-3d !important;
}

.content-skewed-right {
  perspective-origin: right center !important;
}
.content-skewed-right .content-skewed-item {
  transform: rotateY(30deg) rotateX(15deg) !important;
  backface-visibility: hidden !important;
}

.content-skewed-left {
  perspective-origin: left center !important;
}
.content-skewed-left .content-skewed-item {
  transform: rotateY(-30deg) rotateX(15deg) !important;
  backface-visibility: hidden !important;
}

.text-gray-100 {
  color: #f2f6fc !important;
}

.text-gray-200 {
  color: #e0e5ec !important;
}

.text-gray-300 {
  color: #d4dae3 !important;
}

.text-gray-400 {
  color: #c5ccd6 !important;
}

.text-gray-500 {
  color: #a7aeb8 !important;
}

.text-gray-600 {
  color: #69707a !important;
}

.text-gray-700 {
  color: #4a515b !important;
}

.text-gray-800 {
  color: #363d47 !important;
}

.text-gray-900 {
  color: #212832 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-white-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 2.5rem !important;
}

.text-uppercase-expanded {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.text-arrow-icon {
  line-height: 1;
  display: inline-flex;
  align-items: center;
}
.text-arrow-icon svg {
  margin-left: 0.25rem;
}
.text-arrow-icon.small svg {
  height: 0.875rem;
  width: 0.875rem;
}

.line-height-normal {
  line-height: normal !important;
}

.feather-sm {
  height: 0.875rem !important;
  width: 0.875rem !important;
}

.feather-lg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.feather-xl {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.z-1 {
  z-index: 1 !important;
  position: relative !important;
}

.z-2 {
  z-index: 2 !important;
  position: relative !important;
}

.alert-icon {
  position: relative;
  display: flex;
  padding: 0;
}
.alert-icon button.btn-close {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}
.alert-icon .alert-icon-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  font-size: 1.5rem;
}
.alert-icon .alert-icon-aside svg.feather,
.alert-icon .alert-icon-aside svg,
.alert-icon .alert-icon-aside i {
  height: 1.5rem;
  width: 1.5rem;
}
.alert-icon .alert-icon-content {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.alert-primary.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #3A071C;
  --bs-alert-border-color: #3A071C;
}
.alert-primary.alert-solid .alert-link {
  color: #cccccc;
}

.alert-secondary.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #6C3647;
  --bs-alert-border-color: #6C3647;
}
.alert-secondary.alert-solid .alert-link {
  color: #cccccc;
}

.alert-success.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #00ac69;
  --bs-alert-border-color: #00ac69;
}
.alert-success.alert-solid .alert-link {
  color: #cccccc;
}

.alert-info.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #00cfd5;
  --bs-alert-border-color: #00cfd5;
}
.alert-info.alert-solid .alert-link {
  color: #cccccc;
}

.alert-warning.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #f4a100;
  --bs-alert-border-color: #f4a100;
}
.alert-warning.alert-solid .alert-link {
  color: #cccccc;
}

.alert-danger.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #e81500;
  --bs-alert-border-color: #e81500;
}
.alert-danger.alert-solid .alert-link {
  color: #cccccc;
}

.alert-light.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f2f6fc;
  --bs-alert-border-color: #f2f6fc;
}
.alert-light.alert-solid .alert-link {
  color: black;
}

.alert-dark.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #212832;
  --bs-alert-border-color: #212832;
}
.alert-dark.alert-solid .alert-link {
  color: #cccccc;
}

.alert-black.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #000;
  --bs-alert-border-color: #000;
}
.alert-black.alert-solid .alert-link {
  color: #cccccc;
}

.alert-white.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #fff;
  --bs-alert-border-color: #fff;
}
.alert-white.alert-solid .alert-link {
  color: black;
}

.alert-red.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #e81500;
  --bs-alert-border-color: #e81500;
}
.alert-red.alert-solid .alert-link {
  color: #cccccc;
}

.alert-orange.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #f76400;
  --bs-alert-border-color: #f76400;
}
.alert-orange.alert-solid .alert-link {
  color: #cccccc;
}

.alert-yellow.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #f4a100;
  --bs-alert-border-color: #f4a100;
}
.alert-yellow.alert-solid .alert-link {
  color: #cccccc;
}

.alert-green.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #00ac69;
  --bs-alert-border-color: #00ac69;
}
.alert-green.alert-solid .alert-link {
  color: #cccccc;
}

.alert-teal.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #00ba94;
  --bs-alert-border-color: #00ba94;
}
.alert-teal.alert-solid .alert-link {
  color: #cccccc;
}

.alert-cyan.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #00cfd5;
  --bs-alert-border-color: #00cfd5;
}
.alert-cyan.alert-solid .alert-link {
  color: #cccccc;
}

.alert-blue.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #0061f2;
  --bs-alert-border-color: #0061f2;
}
.alert-blue.alert-solid .alert-link {
  color: #cccccc;
}

.alert-indigo.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #5800e8;
  --bs-alert-border-color: #5800e8;
}
.alert-indigo.alert-solid .alert-link {
  color: #cccccc;
}

.alert-purple.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #6900c7;
  --bs-alert-border-color: #6900c7;
}
.alert-purple.alert-solid .alert-link {
  color: #cccccc;
}

.alert-pink.alert-solid {
  --bs-alert-color: #fff;
  --bs-alert-bg: #e30059;
  --bs-alert-border-color: #e30059;
}
.alert-pink.alert-solid .alert-link {
  color: #cccccc;
}

.alert-red-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f1e0e3;
  --bs-alert-border-color: #f1e0e3;
}
.alert-red-soft.alert-solid .alert-link {
  color: black;
}

.alert-orange-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f3e7e3;
  --bs-alert-border-color: #f3e7e3;
}
.alert-orange-soft.alert-solid .alert-link {
  color: black;
}

.alert-yellow-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f2eee3;
  --bs-alert-border-color: #f2eee3;
}
.alert-yellow-soft.alert-solid .alert-link {
  color: black;
}

.alert-green-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #daefed;
  --bs-alert-border-color: #daefed;
}
.alert-green-soft.alert-solid .alert-link {
  color: black;
}

.alert-teal-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #daf0f2;
  --bs-alert-border-color: #daf0f2;
}
.alert-teal-soft.alert-solid .alert-link {
  color: black;
}

.alert-cyan-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #daf2f8;
  --bs-alert-border-color: #daf2f8;
}
.alert-cyan-soft.alert-solid .alert-link {
  color: black;
}

.alert-blue-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #dae7fb;
  --bs-alert-border-color: #dae7fb;
}
.alert-blue-soft.alert-solid .alert-link {
  color: black;
}

.alert-indigo-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #e3ddfa;
  --bs-alert-border-color: #e3ddfa;
}
.alert-indigo-soft.alert-solid .alert-link {
  color: black;
}

.alert-purple-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #e4ddf7;
  --bs-alert-border-color: #e4ddf7;
}
.alert-purple-soft.alert-solid .alert-link {
  color: black;
}

.alert-pink-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f1ddec;
  --bs-alert-border-color: #f1ddec;
}
.alert-pink-soft.alert-solid .alert-link {
  color: black;
}

.alert-primary-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #e0dee6;
  --bs-alert-border-color: #e0dee6;
}
.alert-primary-soft.alert-solid .alert-link {
  color: black;
}

.alert-secondary-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #e5e3ea;
  --bs-alert-border-color: #e5e3ea;
}
.alert-secondary-soft.alert-solid .alert-link {
  color: black;
}

.alert-success-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #daefed;
  --bs-alert-border-color: #daefed;
}
.alert-success-soft.alert-solid .alert-link {
  color: black;
}

.alert-info-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #daf2f8;
  --bs-alert-border-color: #daf2f8;
}
.alert-info-soft.alert-solid .alert-link {
  color: black;
}

.alert-warning-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f2eee3;
  --bs-alert-border-color: #f2eee3;
}
.alert-warning-soft.alert-solid .alert-link {
  color: black;
}

.alert-danger-soft.alert-solid {
  --bs-alert-color: #000;
  --bs-alert-bg: #f1e0e3;
  --bs-alert-border-color: #f1e0e3;
}
.alert-danger-soft.alert-solid .alert-link {
  color: black;
}

.avatar {
  display: inline-flex;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
}
.avatar .avatar-img {
  border-radius: 100%;
  object-fit: cover;
  height: 2rem;
  width: auto;
}

.avatar-xs {
  height: 1.25rem;
  width: 1.25rem;
}
.avatar-xs .avatar-img {
  height: 1rem;
}

.avatar-sm {
  height: 1.75rem;
  width: 1.75rem;
}
.avatar-sm .avatar-img {
  height: 1.5rem;
}

.avatar-lg {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg .avatar-img {
  height: 2.5rem;
}

.avatar-xl {
  height: 3rem;
  width: 3rem;
}
.avatar-xl .avatar-img {
  height: 3rem;
}

.avatar-xxl {
  height: 3.75rem;
  width: 3.75rem;
}
.avatar-xxl .avatar-img {
  height: 3.75rem;
}

.avatar-busy::before,
.avatar-idle::before,
.avatar-offline::before,
.avatar-online::before {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background-color: #d4dae3;
  border: 0.0625rem solid #fff;
}

.avatar-busy::before {
  background-color: #e81500;
}

.avatar-idle::before {
  background-color: #f4a100;
}

.avatar-offline::before {
  background-color: #d4dae3;
}

.avatar-online::before {
  background-color: #00ac69;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
  width: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
}
.btn-icon .feather {
  margin-top: 0 !important;
}
.btn-icon.btn-xl {
  height: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
  width: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
  border-radius: 100%;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  height: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
  width: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  height: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
  width: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
}
.btn-icon.btn-xs {
  height: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
  width: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
  border-radius: 100%;
}
.btn-icon.btn-link {
  text-decoration: none;
}

.btn .feather {
  margin-top: -1px;
  height: 0.875rem;
  width: 0.875rem;
}

.btn-lg .feather, .btn-group-lg > .btn .feather {
  height: 1rem;
  width: 1rem;
}

.btn-sm .feather, .btn-group-sm > .btn .feather {
  height: 0.75rem;
  width: 0.75rem;
}

.btn-xs .feather {
  height: 0.7rem;
  width: 0.7rem;
}

.btn-xl .feather {
  height: 1.125rem;
  width: 1.125rem;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.35rem;
  line-height: 1;
}

.btn-xl {
  padding: 1.25rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}

.btn-facebook {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3b5998;
  --bs-btn-border-color: #3b5998;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #324c81;
  --bs-btn-hover-border-color: #2f477a;
  --bs-btn-focus-shadow-rgb: 88, 114, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2f477a;
  --bs-btn-active-border-color: #2c4372;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3b5998;
  --bs-btn-disabled-border-color: #3b5998;
}

.btn-github {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #292929;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #292929;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-google {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ea4335;
  --bs-btn-border-color: #ea4335;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c7392d;
  --bs-btn-hover-border-color: #bb362a;
  --bs-btn-focus-shadow-rgb: 237, 95, 83;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bb362a;
  --bs-btn-active-border-color: #b03228;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ea4335;
  --bs-btn-disabled-border-color: #ea4335;
}

.btn-twitter {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1da1f2;
  --bs-btn-border-color: #1da1f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1989ce;
  --bs-btn-hover-border-color: #1781c2;
  --bs-btn-focus-shadow-rgb: 63, 175, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1781c2;
  --bs-btn-active-border-color: #1679b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1da1f2;
  --bs-btn-disabled-border-color: #1da1f2;
}

.btn-transparent-dark {
  --bs-btn-color: rgba(33, 40, 50, 0.5);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: rgba(33, 40, 50, 0.5);
  --bs-btn-hover-bg: rgba(33, 40, 50, 0.1);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 11, 14, 17;
  --bs-btn-active-color: rgba(33, 40, 50, 0.5);
  --bs-btn-active-bg: rgba(33, 40, 50, 0.2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(33, 40, 50, 0.35);
  --bs-btn-disabled-bg: rgba(33, 40, 50, 0.1);
  --bs-btn-disabled-border-color: transparent;
  color: rgba(33, 40, 50, 0.5) !important;
}
.btn-transparent-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 40, 50, 0.25) !important;
}

.btn-transparent-light {
  --bs-btn-color: rgba(255, 255, 255, 0.5);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: rgba(255, 255, 255, 0.5);
  --bs-btn-hover-bg: rgba(255, 255, 255, 0.1);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 88, 88, 88;
  --bs-btn-active-color: rgba(255, 255, 255, 0.5);
  --bs-btn-active-bg: rgba(255, 255, 255, 0.2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(255, 255, 255, 0.35);
  --bs-btn-disabled-bg: rgba(255, 255, 255, 0.1);
  --bs-btn-disabled-border-color: transparent;
  color: rgba(255, 255, 255, 0.5) !important;
}
.btn-transparent-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}

.btn-white-10 {
  --bs-btn-color: rgba(255, 255, 255, 0.5);
  --bs-btn-bg: rgba(255, 255, 255, 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: rgba(255, 255, 255, 0.5);
  --bs-btn-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 88, 88, 88;
  --bs-btn-active-color: rgba(255, 255, 255, 0.5);
  --bs-btn-active-bg: rgba(255, 255, 255, 0.2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(255, 255, 255, 0.35);
  --bs-btn-disabled-bg: rgba(255, 255, 255, 0.1);
  --bs-btn-disabled-border-color: transparent;
  color: rgba(255, 255, 255, 0.5) !important;
}
.btn-white-10:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.card .card-header {
  font-weight: 500;
}
.card:not([class*=bg-]) .card-header {
  color: #3A071C;
}

.card.bg-dark .card-header,
.card.bg-dark .card-footer {
  border-color: rgba(255, 255, 255, 0.15);
}

.card .card-header .card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-actions .card-header {
  height: 3.5625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}
.card-header-actions .card-header .dropdown-menu {
  margin-top: 0;
  top: 0.5625rem !important;
}

.card-collapsable .card-header[data-bs-toggle=collapse],
.card-collapsable .card-header[data-toggle=collapse] {
  display: flex;
  text-decoration: none;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow,
.card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow {
  height: 0.9rem;
  width: 0.9rem;
  display: inline-flex;
  font-size: 0.9rem;
  transition: transform 0.1s ease-in-out;
}
.card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow svg,
.card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow i,
.card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow .feather,
.card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow svg,
.card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow i,
.card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow .feather {
  height: 0.9rem;
  width: 0.9rem;
}
.card-collapsable .card-header[data-bs-toggle=collapse].collapsed,
.card-collapsable .card-header[data-toggle=collapse].collapsed {
  border-radius: 0.35rem;
}
.card-collapsable .card-header[data-bs-toggle=collapse].collapsed .card-collapsable-arrow,
.card-collapsable .card-header[data-toggle=collapse].collapsed .card-collapsable-arrow {
  transform: rotate(-90deg);
}

.card-icon {
  overflow: hidden;
}
.card-icon .card-icon-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding: 2rem;
}
.card-icon .card-icon-aside i,
.card-icon .card-icon-aside svg,
.card-icon .card-icon-aside .feather {
  height: 3rem;
  width: 3rem;
}

.card-link {
  text-decoration: none !important;
  color: inherit !important;
}
.card-link:hover {
  color: inherit !important;
}

.card-progress .progress {
  height: 0.5rem;
  background-color: rgba(33, 40, 50, 0.03);
}

.card-scrollable .card-body {
  max-height: 15rem;
  overflow-y: auto;
}

.card-waves .card-body,
.card-angles .card-body {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.card-waves .card-body {
  background-image: url("/assets/img/backgrounds/bg-waves.svg");
}

.card-angles .card-body {
  background-image: url("/assets/img/backgrounds/bg-angles.svg");
}

.card-flag {
  position: absolute;
  font-size: 0.7rem;
  padding: 0.3rem 0.5rem;
  line-height: 1;
}

.card-flag-dark {
  background-color: rgba(33, 40, 50, 0.7);
  color: #fff;
}

.card-flag-light {
  background-color: rgba(255, 255, 255, 0.7);
  color: #69707a;
}

.card-flag-lg {
  font-size: 0.9rem;
  padding: 0.5rem 0.65rem;
}

.card-flag-top-right {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  top: 0.5rem;
  right: 0;
}

.card-flag-top-left {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  top: 0.5rem;
  left: 0;
}

.card-flag-bottom-right {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  bottom: 0.5rem;
  right: 0;
}

.card-flag-bottom-left {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  bottom: 0.5rem;
  left: 0;
}

.dropdown-menu {
  font-size: 0.9rem;
  border: none;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.dropdown-menu .dropdown-header {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
.dropdown-menu .dropdown-item .dropdown-item-icon {
  margin-right: 0.5rem;
  line-height: 1;
}
.dropdown-menu .dropdown-item .dropdown-item-icon svg {
  height: 0.9em;
  width: 0.9em;
}
.dropdown-menu .dropdown-item.active .dropdown-item-icon, .dropdown-menu .dropdown-item:active .dropdown-item-icon {
  color: #fff;
}

.dropdown .dropdown-toggle {
  display: inline-flex;
  align-items: center;
}
.dropdown .dropdown-toggle .dropdown-arrow {
  margin-left: 0.4rem;
  margin-right: 0;
  transition: transform 0.1s ease-in-out;
  font-size: 0.6em;
}
.dropdown .dropdown-toggle.show .dropdown-arrow {
  transform: rotate(90deg);
}

.feather {
  height: 1rem;
  width: 1rem;
  vertical-align: top;
}

.feather-sm {
  height: 0.8rem;
  width: 0.8rem;
}

.icon-stack {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  background-color: #f2f6fc;
  flex-shrink: 0;
}
.icon-stack svg {
  height: 1rem;
  width: 1rem;
}

.icon-stack-sm {
  height: 2rem;
  width: 2rem;
}

.icon-stack-lg {
  height: 4rem;
  width: 4rem;
  font-size: 1.5rem;
}
.icon-stack-lg svg {
  height: 1.5rem;
  width: 1.5rem;
}

.icon-stack-xl {
  height: 5rem;
  width: 5rem;
  font-size: 1.75rem;
}
.icon-stack-xl svg {
  height: 1.75rem;
  width: 1.75rem;
}

.icon-list-social {
  display: flex;
}
.icon-list-social .icon-list-social-link {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.badge-sm {
  font-size: 0.75rem;
}

.badge-md {
  font-size: 1rem;
}

.badge-lg {
  font-size: 1.25rem;
}

.timeline .timeline-item {
  display: flex;
  align-items: flex-start;
}
.timeline .timeline-item .timeline-item-marker {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.timeline .timeline-item .timeline-item-marker .timeline-item-marker-text {
  font-size: 0.875rem;
  width: 6rem;
  color: #a7aeb8;
  text-align: center;
  margin-bottom: 0.5rem;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.timeline .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background-color: #f2f6fc;
  border-radius: 100%;
}
.timeline .timeline-item .timeline-item-content {
  padding-top: 0;
  padding-bottom: 2rem;
  padding-left: 1rem;
  width: 100%;
}
.timeline .timeline-item:last-child .timeline-item-content {
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .timeline .timeline-item .timeline-item-marker {
    flex-direction: row;
    transform: translateX(1.625rem);
    margin-bottom: 0;
  }
  .timeline .timeline-item .timeline-item-marker .timeline-item-marker-text {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .timeline .timeline-item .timeline-item-content {
    padding-top: 0.75rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    border-left: solid 0.25rem #f2f6fc;
  }
  .timeline .timeline-item:last-child .timeline-item-content {
    border-left-color: transparent;
  }
}
.timeline.timeline-sm .timeline-item .timeline-item-marker {
  transform: translateX(0.875rem);
}
.timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-text {
  width: 3rem;
  font-size: 0.7rem;
}
.timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
}
.timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-indicator .feather {
  height: 0.75rem;
  width: 0.75rem;
}
.timeline.timeline-sm .timeline-item .timeline-item-content {
  font-size: 0.875rem;
  padding-top: 0.15rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.timeline.timeline-xs .timeline-item .timeline-item-marker {
  transform: translateX(0.5625rem);
}
.timeline.timeline-xs .timeline-item .timeline-item-marker .timeline-item-marker-text {
  width: 3rem;
  font-size: 0.7rem;
}
.timeline.timeline-xs .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  height: 0.875rem;
  width: 0.875rem;
  font-size: 0.875rem;
  border: 0.125rem solid #fff;
  margin-top: -0.125rem;
}
.timeline.timeline-xs .timeline-item .timeline-item-content {
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.step {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.step .step-item {
  flex: 1 1 0;
  position: relative;
  text-align: center;
}
.step .step-item:not(:first-child)::before {
  background-color: #69707a;
  content: "";
  height: 0.125rem;
  left: -50%;
  position: absolute;
  top: 0.4375rem;
  width: 100%;
  z-index: 0;
}
.step .step-item .step-item-link {
  color: #69707a;
  position: relative;
  display: inline-block;
  padding-top: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
  z-index: 1;
}
.step .step-item .step-item-link::before {
  background-color: #69707a;
  border: 0.125rem solid #fff;
  border-radius: 100%;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}
.step .step-item .step-item-link.disabled {
  pointer-events: none;
  cursor: default;
}
.step .step-item.active a::before {
  background: #fff;
  border: 0.125rem solid #69707a;
}
.step .step-item.active ~ .step-item .step-item-link {
  color: #d4dae3;
}
.step .step-item.active ~ .step-item .step-item-link::before {
  background: #d4dae3;
}
.step .step-item.active ~ .step-item::before {
  background: #d4dae3;
}

.step-lg .step-item:not(:first-child)::before {
  height: 0.25rem;
  top: 0.625rem;
}
.step-lg .step-item .step-item-link {
  padding-top: 2rem;
}
.step-lg .step-item .step-item-link::before {
  border-width: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}
.step-lg .step-item.active a::before {
  border-width: 0.25rem;
}

.step-primary .step-item:not(:first-child)::before {
  background-color: #3A071C;
}
.step-primary .step-item .step-item-link {
  color: #3A071C;
}
.step-primary .step-item .step-item-link::before {
  background-color: #3A071C;
}
.step-primary .step-item.active a::before {
  border-color: #3A071C;
}

.step-secondary .step-item:not(:first-child)::before {
  background-color: #6C3647;
}
.step-secondary .step-item .step-item-link {
  color: #6C3647;
}
.step-secondary .step-item .step-item-link::before {
  background-color: #6C3647;
}
.step-secondary .step-item.active a::before {
  border-color: #6C3647;
}

.step-success .step-item:not(:first-child)::before {
  background-color: #00ac69;
}
.step-success .step-item .step-item-link {
  color: #00ac69;
}
.step-success .step-item .step-item-link::before {
  background-color: #00ac69;
}
.step-success .step-item.active a::before {
  border-color: #00ac69;
}

.step-info .step-item:not(:first-child)::before {
  background-color: #00cfd5;
}
.step-info .step-item .step-item-link {
  color: #00cfd5;
}
.step-info .step-item .step-item-link::before {
  background-color: #00cfd5;
}
.step-info .step-item.active a::before {
  border-color: #00cfd5;
}

.step-warning .step-item:not(:first-child)::before {
  background-color: #f4a100;
}
.step-warning .step-item .step-item-link {
  color: #f4a100;
}
.step-warning .step-item .step-item-link::before {
  background-color: #f4a100;
}
.step-warning .step-item.active a::before {
  border-color: #f4a100;
}

.step-danger .step-item:not(:first-child)::before {
  background-color: #e81500;
}
.step-danger .step-item .step-item-link {
  color: #e81500;
}
.step-danger .step-item .step-item-link::before {
  background-color: #e81500;
}
.step-danger .step-item.active a::before {
  border-color: #e81500;
}

.step-light .step-item:not(:first-child)::before {
  background-color: #f2f6fc;
}
.step-light .step-item .step-item-link {
  color: #f2f6fc;
}
.step-light .step-item .step-item-link::before {
  background-color: #f2f6fc;
}
.step-light .step-item.active a::before {
  border-color: #f2f6fc;
}

.step-dark .step-item:not(:first-child)::before {
  background-color: #212832;
}
.step-dark .step-item .step-item-link {
  color: #212832;
}
.step-dark .step-item .step-item-link::before {
  background-color: #212832;
}
.step-dark .step-item.active a::before {
  border-color: #212832;
}

.step-black .step-item:not(:first-child)::before {
  background-color: #000;
}
.step-black .step-item .step-item-link {
  color: #000;
}
.step-black .step-item .step-item-link::before {
  background-color: #000;
}
.step-black .step-item.active a::before {
  border-color: #000;
}

.step-white .step-item:not(:first-child)::before {
  background-color: #fff;
}
.step-white .step-item .step-item-link {
  color: #fff;
}
.step-white .step-item .step-item-link::before {
  background-color: #fff;
}
.step-white .step-item.active a::before {
  border-color: #fff;
}

.step-red .step-item:not(:first-child)::before {
  background-color: #e81500;
}
.step-red .step-item .step-item-link {
  color: #e81500;
}
.step-red .step-item .step-item-link::before {
  background-color: #e81500;
}
.step-red .step-item.active a::before {
  border-color: #e81500;
}

.step-orange .step-item:not(:first-child)::before {
  background-color: #f76400;
}
.step-orange .step-item .step-item-link {
  color: #f76400;
}
.step-orange .step-item .step-item-link::before {
  background-color: #f76400;
}
.step-orange .step-item.active a::before {
  border-color: #f76400;
}

.step-yellow .step-item:not(:first-child)::before {
  background-color: #f4a100;
}
.step-yellow .step-item .step-item-link {
  color: #f4a100;
}
.step-yellow .step-item .step-item-link::before {
  background-color: #f4a100;
}
.step-yellow .step-item.active a::before {
  border-color: #f4a100;
}

.step-green .step-item:not(:first-child)::before {
  background-color: #00ac69;
}
.step-green .step-item .step-item-link {
  color: #00ac69;
}
.step-green .step-item .step-item-link::before {
  background-color: #00ac69;
}
.step-green .step-item.active a::before {
  border-color: #00ac69;
}

.step-teal .step-item:not(:first-child)::before {
  background-color: #00ba94;
}
.step-teal .step-item .step-item-link {
  color: #00ba94;
}
.step-teal .step-item .step-item-link::before {
  background-color: #00ba94;
}
.step-teal .step-item.active a::before {
  border-color: #00ba94;
}

.step-cyan .step-item:not(:first-child)::before {
  background-color: #00cfd5;
}
.step-cyan .step-item .step-item-link {
  color: #00cfd5;
}
.step-cyan .step-item .step-item-link::before {
  background-color: #00cfd5;
}
.step-cyan .step-item.active a::before {
  border-color: #00cfd5;
}

.step-blue .step-item:not(:first-child)::before {
  background-color: #0061f2;
}
.step-blue .step-item .step-item-link {
  color: #0061f2;
}
.step-blue .step-item .step-item-link::before {
  background-color: #0061f2;
}
.step-blue .step-item.active a::before {
  border-color: #0061f2;
}

.step-indigo .step-item:not(:first-child)::before {
  background-color: #5800e8;
}
.step-indigo .step-item .step-item-link {
  color: #5800e8;
}
.step-indigo .step-item .step-item-link::before {
  background-color: #5800e8;
}
.step-indigo .step-item.active a::before {
  border-color: #5800e8;
}

.step-purple .step-item:not(:first-child)::before {
  background-color: #6900c7;
}
.step-purple .step-item .step-item-link {
  color: #6900c7;
}
.step-purple .step-item .step-item-link::before {
  background-color: #6900c7;
}
.step-purple .step-item.active a::before {
  border-color: #6900c7;
}

.step-pink .step-item:not(:first-child)::before {
  background-color: #e30059;
}
.step-pink .step-item .step-item-link {
  color: #e30059;
}
.step-pink .step-item .step-item-link::before {
  background-color: #e30059;
}
.step-pink .step-item.active a::before {
  border-color: #e30059;
}

.step-red-soft .step-item:not(:first-child)::before {
  background-color: #f1e0e3;
}
.step-red-soft .step-item .step-item-link {
  color: #f1e0e3;
}
.step-red-soft .step-item .step-item-link::before {
  background-color: #f1e0e3;
}
.step-red-soft .step-item.active a::before {
  border-color: #f1e0e3;
}

.step-orange-soft .step-item:not(:first-child)::before {
  background-color: #f3e7e3;
}
.step-orange-soft .step-item .step-item-link {
  color: #f3e7e3;
}
.step-orange-soft .step-item .step-item-link::before {
  background-color: #f3e7e3;
}
.step-orange-soft .step-item.active a::before {
  border-color: #f3e7e3;
}

.step-yellow-soft .step-item:not(:first-child)::before {
  background-color: #f2eee3;
}
.step-yellow-soft .step-item .step-item-link {
  color: #f2eee3;
}
.step-yellow-soft .step-item .step-item-link::before {
  background-color: #f2eee3;
}
.step-yellow-soft .step-item.active a::before {
  border-color: #f2eee3;
}

.step-green-soft .step-item:not(:first-child)::before {
  background-color: #daefed;
}
.step-green-soft .step-item .step-item-link {
  color: #daefed;
}
.step-green-soft .step-item .step-item-link::before {
  background-color: #daefed;
}
.step-green-soft .step-item.active a::before {
  border-color: #daefed;
}

.step-teal-soft .step-item:not(:first-child)::before {
  background-color: #daf0f2;
}
.step-teal-soft .step-item .step-item-link {
  color: #daf0f2;
}
.step-teal-soft .step-item .step-item-link::before {
  background-color: #daf0f2;
}
.step-teal-soft .step-item.active a::before {
  border-color: #daf0f2;
}

.step-cyan-soft .step-item:not(:first-child)::before {
  background-color: #daf2f8;
}
.step-cyan-soft .step-item .step-item-link {
  color: #daf2f8;
}
.step-cyan-soft .step-item .step-item-link::before {
  background-color: #daf2f8;
}
.step-cyan-soft .step-item.active a::before {
  border-color: #daf2f8;
}

.step-blue-soft .step-item:not(:first-child)::before {
  background-color: #dae7fb;
}
.step-blue-soft .step-item .step-item-link {
  color: #dae7fb;
}
.step-blue-soft .step-item .step-item-link::before {
  background-color: #dae7fb;
}
.step-blue-soft .step-item.active a::before {
  border-color: #dae7fb;
}

.step-indigo-soft .step-item:not(:first-child)::before {
  background-color: #e3ddfa;
}
.step-indigo-soft .step-item .step-item-link {
  color: #e3ddfa;
}
.step-indigo-soft .step-item .step-item-link::before {
  background-color: #e3ddfa;
}
.step-indigo-soft .step-item.active a::before {
  border-color: #e3ddfa;
}

.step-purple-soft .step-item:not(:first-child)::before {
  background-color: #e4ddf7;
}
.step-purple-soft .step-item .step-item-link {
  color: #e4ddf7;
}
.step-purple-soft .step-item .step-item-link::before {
  background-color: #e4ddf7;
}
.step-purple-soft .step-item.active a::before {
  border-color: #e4ddf7;
}

.step-pink-soft .step-item:not(:first-child)::before {
  background-color: #f1ddec;
}
.step-pink-soft .step-item .step-item-link {
  color: #f1ddec;
}
.step-pink-soft .step-item .step-item-link::before {
  background-color: #f1ddec;
}
.step-pink-soft .step-item.active a::before {
  border-color: #f1ddec;
}

.step-primary-soft .step-item:not(:first-child)::before {
  background-color: #e0dee6;
}
.step-primary-soft .step-item .step-item-link {
  color: #e0dee6;
}
.step-primary-soft .step-item .step-item-link::before {
  background-color: #e0dee6;
}
.step-primary-soft .step-item.active a::before {
  border-color: #e0dee6;
}

.step-secondary-soft .step-item:not(:first-child)::before {
  background-color: #e5e3ea;
}
.step-secondary-soft .step-item .step-item-link {
  color: #e5e3ea;
}
.step-secondary-soft .step-item .step-item-link::before {
  background-color: #e5e3ea;
}
.step-secondary-soft .step-item.active a::before {
  border-color: #e5e3ea;
}

.step-success-soft .step-item:not(:first-child)::before {
  background-color: #daefed;
}
.step-success-soft .step-item .step-item-link {
  color: #daefed;
}
.step-success-soft .step-item .step-item-link::before {
  background-color: #daefed;
}
.step-success-soft .step-item.active a::before {
  border-color: #daefed;
}

.step-info-soft .step-item:not(:first-child)::before {
  background-color: #daf2f8;
}
.step-info-soft .step-item .step-item-link {
  color: #daf2f8;
}
.step-info-soft .step-item .step-item-link::before {
  background-color: #daf2f8;
}
.step-info-soft .step-item.active a::before {
  border-color: #daf2f8;
}

.step-warning-soft .step-item:not(:first-child)::before {
  background-color: #f2eee3;
}
.step-warning-soft .step-item .step-item-link {
  color: #f2eee3;
}
.step-warning-soft .step-item .step-item-link::before {
  background-color: #f2eee3;
}
.step-warning-soft .step-item.active a::before {
  border-color: #f2eee3;
}

.step-danger-soft .step-item:not(:first-child)::before {
  background-color: #f1e0e3;
}
.step-danger-soft .step-item .step-item-link {
  color: #f1e0e3;
}
.step-danger-soft .step-item .step-item-link::before {
  background-color: #f1e0e3;
}
.step-danger-soft .step-item.active a::before {
  border-color: #f1e0e3;
}

.form-check.form-check-solid .form-check-input {
  background-color: #eef2f8;
  border-color: #eef2f8;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: #3A071C;
}

.form-control-solid {
  background-color: #eef2f8;
  border-color: #eef2f8;
}
.form-control-solid:focus {
  background-color: #eef2f8;
}

.input-group-joined {
  border-color: #c5ccd6;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.4375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group-joined:focus-within {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
}
.input-group-joined .form-control, .input-group-joined .dataTable-input {
  border: none;
}
.input-group-joined .form-control:focus, .input-group-joined .dataTable-input:focus {
  box-shadow: none;
  outline: none;
}
.input-group-joined .input-group-text,
.input-group-joined .input-group-text {
  border: none;
  background-color: #fff;
}

.input-group-joined-xl .form-control, .input-group-joined-xl .dataTable-input {
  height: 4rem;
  font-size: 1.25rem;
}

.input-group-joined.input-group-solid {
  border: 0;
  background-color: #eef2f8;
}
.input-group-joined.input-group-solid .form-control, .input-group-joined.input-group-solid .dataTable-input,
.input-group-joined.input-group-solid .input-group-text {
  background-color: transparent;
}

#layoutSidenav {
  display: flex;
}
#layoutSidenav #layoutSidenav_nav {
  flex-basis: 15rem;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  transform: translateX(-15rem);
}
#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 3.625rem);
  margin-left: -15rem;
}

.sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}
.sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-15rem);
  }
  .sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -15rem;
  }
  .sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}
.nav-fixed .topnav {
  z-index: 1039;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 15rem;
  height: 100vh;
  z-index: 1038;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav {
  padding-top: 3.625rem;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu {
  overflow-y: overlay;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu::-webkit-scrollbar {
  width: 0.75rem;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  border-width: 0.2rem;
  border-style: solid;
  background-clip: padding-box;
  background-color: rgba(33, 40, 50, 0.2);
  border-color: transparent;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu::-webkit-scrollbar-corner {
  background-color: transparent;
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu::-webkit-scrollbar-track {
  background: inherit;
}
@media (pointer: fine) and (hover: hover) {
  .nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu {
    overflow-y: hidden;
  }
  .nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu:hover {
    overflow-y: overlay;
  }
}
@media (pointer: coarse) and (hover: none) {
  .nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu {
    overflow-y: overlay;
  }
}
@-moz-document url-prefix() {
  .nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav .sidenav-menu {
    overflow-y: auto;
  }
}
.nav-fixed #layoutSidenav #layoutSidenav_nav .sidenav.sidenav-dark .sidenav-menu::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(0, 0, 0, 0);
}
.nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 15rem;
  top: 3.625rem;
}

.layout-rtl #layoutSidenav #layoutSidenav_nav {
  transform: translateX(15rem);
}
.layout-rtl #layoutSidenav #layoutSidenav_content {
  margin-left: 0;
  margin-right: -15rem;
}
.layout-rtl.sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}
@media (min-width: 992px) {
  .layout-rtl #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  .layout-rtl #layoutSidenav #layoutSidenav_content {
    margin-right: 0;
    transition: margin 0.15s ease-in-out;
  }
  .layout-rtl.sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(15rem);
  }
  .layout-rtl.sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-right: -15rem;
  }
}

.layout-rtl.nav-fixed #layoutSidenav #layoutSidenav_nav {
  left: auto;
}
.layout-rtl.nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 0;
  padding-right: 15rem;
}

.dropdown-notifications {
  position: static;
}
.dropdown-notifications .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  width: calc(100% - 1.5rem);
  right: 0.75rem;
  max-height: calc(21rem - 1px);
  overflow-x: hidden;
  overflow-y: overlay;
}
.dropdown-notifications .dropdown-menu::-webkit-scrollbar {
  width: 0.75rem;
}
.dropdown-notifications .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10rem;
  border-width: 0.2rem;
  border-style: solid;
  background-clip: padding-box;
  background-color: rgba(33, 40, 50, 0.2);
  border-color: transparent;
}
.dropdown-notifications .dropdown-menu::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.dropdown-notifications .dropdown-menu::-webkit-scrollbar-corner {
  background-color: transparent;
}
.dropdown-notifications .dropdown-menu::-webkit-scrollbar-track {
  background: inherit;
}
@media (pointer: fine) and (hover: hover) {
  .dropdown-notifications .dropdown-menu {
    overflow-y: hidden;
  }
  .dropdown-notifications .dropdown-menu:hover {
    overflow-y: overlay;
  }
}
@media (pointer: coarse) and (hover: none) {
  .dropdown-notifications .dropdown-menu {
    overflow-y: overlay;
  }
}
@-moz-document url-prefix() {
  .dropdown-notifications .dropdown-menu {
    overflow-y: auto;
  }
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-header {
  background-color: #3A071C;
  color: #fff !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-header svg {
  height: 0.7rem;
  width: 0.7rem;
  opacity: 0.7;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e5ec;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon,
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;
  flex-shrink: 0;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon {
  background-color: #3A071C;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon svg {
  text-align: center;
  font-size: 0.85rem;
  color: #fff;
  height: 0.85rem;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-details {
  color: #a7aeb8;
  font-size: 0.7rem;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
  font-size: 0.9rem;
  max-width: calc(100vw - 8.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-actions .btn-sm, .dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-actions .btn-group-sm > .btn {
  font-size: 0.7rem;
  padding: 0.15rem 0.35rem;
  cursor: pointer;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-footer {
  justify-content: center;
  font-size: 0.8rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #a7aeb8;
  cursor: pointer;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-footer .dropdown-notifications-footer-icon {
  height: 1em;
  width: 1em;
  margin-left: 0.25rem;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-footer:active {
  color: #fff;
}
@media (min-width: 576px) {
  .dropdown-notifications {
    position: relative;
  }
  .dropdown-notifications .dropdown-menu {
    width: auto;
    min-width: 18.75rem;
    right: 0;
  }
  .dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
    max-width: 13rem;
  }
}

.dropdown-user .dropdown-menu {
  min-width: 13rem;
}
.dropdown-user .dropdown-menu .dropdown-header {
  text-transform: none;
  letter-spacing: normal;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
  border-radius: 100%;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details {
  font-weight: 400;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name {
  color: #212832;
  font-weight: 500;
  font-size: 0.9rem;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email {
  color: #69707a;
  font-size: 0.75rem;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-user .dropdown-menu .dropdown-item {
  align-items: center;
}
.dropdown-user .dropdown-menu .dropdown-item .dropdown-item-icon {
  color: #a7aeb8;
}
.dropdown-user .dropdown-menu .dropdown-item .dropdown-item-icon svg {
  vertical-align: inherit;
}
.dropdown-user .dropdown-menu .dropdown-item:active .dropdown-item-icon {
  color: #fff;
}

.footer-admin {
  height: 5rem;
  display: flex;
  align-items: center;
}
.footer-admin.footer-dark {
  color: rgba(255, 255, 255, 0.6);
}
.footer-admin.footer-light {
  color: #a7aeb8;
}

.nav .nav-link .nav-link-icon,
.sidenav-menu .nav-link .nav-link-icon {
  margin-right: 0.5rem;
}

.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}
.nav-borders .nav-link.active {
  color: #3A071C;
  border-bottom-color: #3A071C;
}
.nav-borders .nav-link.disabled {
  color: #c5ccd6;
}
.nav-borders.flex-column .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: 0;
  border-bottom: none;
  border-right-width: 0.125rem;
  border-right-style: solid;
  border-right-color: transparent;
}
.nav-borders.flex-column .nav-link.active {
  border-right-color: #3A071C;
}

.navbar .dropdown-menu {
  top: calc(100% + 0.5rem + 0.5rem) !important;
  font-size: 0.9rem;
}
.navbar .dropdown-menu .dropdown-header {
  color: #a7aeb8;
}
.navbar .form-control, .navbar .dataTable-input {
  font-size: 0.9rem;
}

.topnav {
  padding-left: 0;
  height: 3.625rem;
  z-index: 1039;
  font-size: 0.9rem;
}
.topnav .navbar-brand {
  width: 15rem;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}
.topnav .navbar-brand img {
  height: 0.8rem;
}
@media (min-width: 992px) {
  .topnav .navbar-brand {
    width: 12rem;
  }
}
.topnav.navbar-dark #sidebarToggle {
  color: rgba(255, 255, 255, 0.5);
}
.topnav.navbar-dark .navbar-brand {
  color: #fff;
}
.topnav.navbar-light #sidebarToggle {
  color: #212832;
}
.topnav.navbar-light .navbar-brand {
  color: #363d47;
}
.topnav .dropdown {
  position: static;
}
.topnav .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
  left: 0.75rem;
}
@media (min-width: 576px) {
  .topnav .dropdown {
    position: relative;
  }
  .topnav .dropdown .dropdown-menu {
    width: auto;
    min-width: 15rem;
    right: 0;
    left: auto;
  }
}

:target:before {
  content: "";
  display: block;
  height: 5.125rem;
  margin: -5.125rem 0 0;
}

.nav-sticky {
  top: 5.125rem;
  font-size: 0.9rem;
}
.nav-sticky .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.nav-sticky .nav-link.active {
  font-weight: 600;
}
.nav-sticky ul.nav > li.nav-item > .nav {
  display: none;
}
.nav-sticky ul.nav > li.nav-item > a.nav-link.active ~ .nav {
  display: flex;
}

.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  font-size: 0.9rem;
}
.sidenav .sidenav-menu {
  flex-grow: 1;
}
.sidenav .sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}
.sidenav .sidenav-menu .nav .sidenav-menu-heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sidenav .sidenav-menu .nav .nav-link {
  display: flex;
  align-items: center;
  line-height: normal;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
}
.sidenav .sidenav-menu .nav .nav-link .nav-link-icon {
  font-size: 0.9rem;
  padding-right: 0.5rem;
  display: inline-flex;
}
.sidenav .sidenav-menu .nav .nav-link .nav-link-icon .feather {
  width: 1rem;
  height: 1rem;
}
.sidenav .sidenav-menu .nav .nav-link.active {
  font-weight: 600;
}
.sidenav .sidenav-menu .nav .nav-link.active .nav-icon {
  color: #3A071C;
}
.sidenav .sidenav-menu .nav .nav-link .sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}
.sidenav .sidenav-menu .nav .nav-link.collapsed .sidenav-collapse-arrow {
  transform: rotate(-90deg);
}
.sidenav .sidenav-menu .nav .nav-link .badge {
  padding: 0.125rem 0.25rem;
}
.sidenav .sidenav-menu .nav .sidenav-menu-nested {
  flex-direction: column;
  margin-left: 1.4375rem;
  border-left-style: solid;
  border-left-width: thin;
  padding-left: 0.5625rem;
}
.sidenav .sidenav-footer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 5rem;
}
.sidenav .sidenav-footer .sidenav-footer-content {
  font-size: 0.9rem;
}
.sidenav .sidenav-footer .sidenav-footer-content .sidenav-footer-subtitle {
  font-size: 0.75rem;
}
.sidenav .sidenav-footer .sidenav-footer-content .sidenav-footer-title {
  font-weight: 500;
}

.sidenav-dark {
  background-color: #272f3b;
  color: rgba(255, 255, 255, 0.5);
}
.sidenav-dark .sidenav-menu .sidenav-menu-heading {
  color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.sidenav-dark .sidenav-menu .nav-link .nav-link-icon {
  color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link .sidenav-collapse-arrow {
  color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link:hover {
  color: #fff;
}
.sidenav-dark .sidenav-menu .nav-link.active {
  color: #fff;
}
.sidenav-dark .sidenav-menu .nav-link.active .nav-link-icon {
  color: #fff;
}
.sidenav-dark .sidenav-menu-nested {
  border-left-color: rgba(255, 255, 255, 0.15);
}
.sidenav-dark .sidenav-footer {
  background-color: rgba(0, 0, 0, 0.25);
}

.sidenav-light {
  background-color: #fff;
  color: #212832;
}
.sidenav-light .sidenav-menu .sidenav-menu-heading {
  color: #a7aeb8;
}
.sidenav-light .sidenav-menu .nav-link {
  color: #212832;
}
.sidenav-light .sidenav-menu .nav-link .nav-link-icon {
  color: #a7aeb8;
}
.sidenav-light .sidenav-menu .nav-link .sidenav-collapse-arrow {
  color: #a7aeb8;
}
.sidenav-light .sidenav-menu .nav-link:hover {
  color: #3A071C;
}
.sidenav-light .sidenav-menu .nav-link.active {
  color: #3A071C;
}
.sidenav-light .sidenav-menu .nav-link.active .nav-link-icon {
  color: #3A071C;
}
.sidenav-light .sidenav-menu-nested {
  border-left-color: #d4dae3;
}
.sidenav-light .sidenav-footer {
  background-color: rgba(33, 40, 50, 0.05);
}
.sidenav-light .sidenav-footer .sidenav-footer-subtitle {
  color: #69707a;
}

.page-header .page-header-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.page-header .page-header-content .page-header-title {
  line-height: normal;
  font-size: 2.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.page-header .page-header-content .page-header-title .page-header-icon {
  margin-right: 0.5rem;
  display: inline-flex;
}
.page-header .page-header-content .page-header-title .page-header-icon .feather {
  height: 1.75rem;
  width: 1.75rem;
}
.page-header .page-header-content .page-header-subtitle {
  font-size: 1.15rem;
  margin-top: 0.25rem;
}
.page-header .page-header-search .input-group-joined {
  border-color: transparent;
}
.page-header .page-header-search .input-group-joined .form-control, .page-header .page-header-search .input-group-joined .dataTable-input {
  height: 4rem;
  font-size: 1.25rem;
}
.page-header .page-header-search .input-group-joined:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(58, 7, 28, 0.25);
  border-color: transparent;
}

.page-header-compact .page-header-content {
  padding: 0;
}
.page-header-compact .page-header-content .page-header-title {
  font-size: 1rem;
}
.page-header-compact .page-header-content .page-header-title .page-header-icon .feather {
  height: 1rem;
  width: 1rem;
}

.page-header.page-header-dark {
  color: rgba(255, 255, 255, 0.5);
}
.page-header.page-header-dark .page-header-title {
  color: #fff;
}
.page-header.page-header-dark .page-header-title .page-header-icon {
  color: rgba(255, 255, 255, 0.5);
}
.page-header.page-header-dark .page-header-subtitle {
  color: rgba(255, 255, 255, 0.5);
}
.page-header.page-header-dark .breadcrumb {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.page-header.page-header-dark .breadcrumb .breadcrumb-item,
.page-header.page-header-dark .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.35);
}
.page-header.page-header-dark .breadcrumb .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.6);
}
.page-header.page-header-dark .page-header-search .input-group-joined:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
  border-color: transparent;
}

.page-header.page-header-light {
  color: rgba(33, 40, 50, 0.5);
}
.page-header.page-header-light .page-header-title {
  color: #212832;
}
.page-header.page-header-light .page-header-title .page-header-icon {
  color: rgba(33, 40, 50, 0.5);
}
.page-header.page-header-light .page-header-subtitle {
  color: rgba(33, 40, 50, 0.5);
}
.page-header.page-header-light .breadcrumb {
  background-color: rgba(0, 0, 0, 0.05);
}
.page-header.page-header-light .breadcrumb .breadcrumb-item,
.page-header.page-header-light .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #69707a;
}
.page-header.page-header-light .breadcrumb .breadcrumb-item a {
  color: #3A071C;
}

.img-account-profile {
  height: 10rem;
}

.cc-color-visa {
  color: #1a1f71;
}

.cc-color-mastercard {
  color: #cc0000;
}

.cc-color-amex {
  color: #002663;
}

.table-billing-history {
  font-size: 0.85rem;
}
.table-billing-history thead th {
  border-top: none;
}
.table-billing-history th,
.table-billing-history td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.icons-org-create {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.icons-org-create .icon-users {
  height: 4rem;
  width: 4rem;
}
.icons-org-create .icon-users circle,
.icons-org-create .icon-users path:first-of-type {
  color: #3A071C;
}
.icons-org-create .icon-users path {
  color: rgba(58, 7, 28, 0.35);
}
.icons-org-create .icon-plus {
  color: #3A071C;
  position: absolute;
  font-size: 1.5rem;
  top: -0.5rem;
  right: -0.75rem;
}

.icons-org-join {
  display: inline-flex;
  position: relative;
}
.icons-org-join .icon-user {
  height: 4rem;
  width: 4rem;
  color: #6C3647;
}
.icons-org-join .icon-arrow {
  font-size: 2rem;
  margin: 1rem 0.5rem 1rem 0;
  color: #6C3647;
}
.icons-org-join .icon-users {
  margin: 0.5rem 0;
  height: 3rem;
  width: 3rem;
}
.icons-org-join .icon-users circle,
.icons-org-join .icon-users path:first-of-type {
  color: rgba(108, 54, 71, 0.5);
}
.icons-org-join .icon-users path {
  color: rgba(108, 54, 71, 0.25);
}

.org-or-divider {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #e0e5ec;
}
.org-or-divider .org-or {
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #e0e5ec;
  font-size: 80%;
  color: #6C3647;
}

.invoice .invoice-brand-img {
  height: 5rem;
  width: 5rem;
}
.invoice .table tbody tr td, .invoice .dataTable-table tbody tr td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .pricing-detailed .pricing-detailed-behind {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.pricing-columns .pricing-columns-price {
  font-size: 2rem;
  color: #212832;
}
.pricing-columns .pricing-columns-price span {
  font-weight: normal;
  font-size: 1rem;
  color: #69707a;
}
.pricing-columns .list-group-flush .list-group-item {
  display: flex;
  align-items: center;
}

.nav-wizard .nav-link {
  display: flex;
  align-items: center;
  padding: 2rem;
}
.nav-wizard .nav-link .wizard-step-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 0.35rem;
  background-color: #3A071C;
  color: white;
}
.nav-wizard .nav-link .wizard-step-icon i,
.nav-wizard .nav-link .wizard-step-icon svg,
.nav-wizard .nav-link .wizard-step-icon .feather {
  color: white;
}
.nav-wizard .nav-link .wizard-step-text {
  line-height: 1;
  text-align: left;
}
.nav-wizard .nav-link .wizard-step-text .wizard-step-text-name {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #3A071C;
}
.nav-wizard .nav-link .wizard-step-text .wizard-step-text-details {
  font-weight: 400;
  font-size: 0.875rem;
  color: #69707a;
}
.nav-wizard .nav-link.active .wizard-step-icon {
  background-color: #fff;
  color: #3A071C;
}
.nav-wizard .nav-link.active .wizard-step-icon i,
.nav-wizard .nav-link.active .wizard-step-icon svg,
.nav-wizard .nav-link.active .wizard-step-icon .feather {
  color: #3A071C;
}
.nav-wizard .nav-link.active .wizard-step-text .wizard-step-text-name {
  color: #fff;
}
.nav-wizard .nav-link.active .wizard-step-text .wizard-step-text-details {
  color: rgba(255, 255, 255, 0.5);
}

:root {
  --litepicker-month-header-color: #4a515b;
  --litepicker-button-prev-month-color: #69707a;
  --litepicker-button-next-month-color: #69707a;
  --litepicker-button-prev-month-color-hover: #212832;
  --litepicker-button-next-month-color-hover: #212832;
  --litepicker-month-weekday-color: #69707a;
  --litepicker-month-week-number-color: #69707a;
  --litepicker-day-width: 34px;
  --litepicker-day-color: #363d47;
  --litepicker-day-color-hover: #3A071C;
  --litepicker-is-today-color: #69707a;
  --litepicker-is-in-range-color: rgba(58, 7, 28, 0.15);
  --litepicker-is-start-color: #fff;
  --litepicker-is-start-color-bg: #3A071C;
  --litepicker-is-end-color: #fff;
  --litepicker-is-end-color-bg: #3A071C;
  --litepicker-button-apply-color-bg: #3A071C;
  --litepicker-button-reset-color-hover: #3A071C;
  --litepicker-highlighted-day-color: #363d47;
  --litepicker-highlighted-day-color-bg: rgba(58, 7, 28, 0.15);
}

.show-week-numbers {
  --litepicker-month-width: calc(var(--litepicker-day-width) * 8);
}

.litepicker {
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.875rem !important;
}
.litepicker .container__months {
  box-shadow: none !important;
  border-radius: 0.25rem !important;
}
@media (max-width: 709px) {
  .litepicker .container__months.columns-2 {
    width: calc(var(--litepicker-month-width) + 10px) !important;
  }
}
.litepicker .container__months .month-item-header {
  font-weight: normal !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.75rem !important;
  color: var(--litepicker-month-header-color) !important;
}
.litepicker .container__months .month-item-header div > .month-item-name {
  margin-right: 0.25rem !important;
  font-weight: 500 !important;
}
.litepicker .container__months .month-item-header div > .month-item-year {
  font-weight: 500 !important;
}
.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
  transition: none !important;
  cursor: pointer !important;
}
.litepicker .container__months .month-item-header .button-previous-month > svg,
.litepicker .container__months .month-item-header .button-previous-month > img,
.litepicker .container__months .month-item-header .button-next-month > svg,
.litepicker .container__months .month-item-header .button-next-month > img {
  transform: scale(0.75);
}
.litepicker .container__months .month-item-header .button-previous-month {
  color: var(--litepicker-button-prev-month-color) !important;
}
.litepicker .container__months .month-item-header .button-previous-month > svg,
.litepicker .container__months .month-item-header .button-previous-month > img {
  fill: var(--litepicker-button-prev-month-color) !important;
}
.litepicker .container__months .month-item-header .button-previous-month:hover {
  color: var(--litepicker-button-prev-month-color-hover) !important;
}
.litepicker .container__months .month-item-header .button-previous-month:hover > svg {
  fill: var(--litepicker-button-prev-month-color-hover) !important;
}
.litepicker .container__months .month-item-header .button-next-month {
  color: var(--litepicker-button-next-month-color) !important;
}
.litepicker .container__months .month-item-header .button-next-month > svg,
.litepicker .container__months .month-item-header .button-next-month > img {
  fill: var(--litepicker-button-next-month-color) !important;
}
.litepicker .container__months .month-item-header .button-next-month:hover {
  color: var(--litepicker-button-next-month-color-hover) !important;
}
.litepicker .container__months .month-item-header .button-next-month:hover > svg {
  fill: var(--litepicker-button-next-month-color-hover) !important;
}
.litepicker .container__months .month-item-weekdays-row {
  color: var(--litepicker-month-weekday-color) !important;
}
.litepicker .container__days > div,
.litepicker .container__days > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0;
  width: var(--litepicker-day-width) !important;
  height: var(--litepicker-day-width) !important;
}
.litepicker .container__days .day-item {
  color: var(--litepicker-day-color) !important;
  border-radius: 100% !important;
  transition: none !important;
  cursor: pointer !important;
}
.litepicker .container__days .day-item:hover {
  color: var(--litepicker-day-color-hover) !important;
  box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover) !important;
}
.litepicker .container__days .day-item.is-today {
  box-shadow: inset 0 0 0 1px #69707a;
  color: var(--litepicker-is-today-color) !important;
}
.litepicker .container__days .day-item.is-in-range {
  position: relative;
  background-color: transparent !important;
}
.litepicker .container__days .day-item.is-in-range:after {
  content: "";
  display: block;
  height: 100%;
  width: calc(100% + 4px);
  position: absolute;
  background-color: var(--litepicker-is-in-range-color);
}
.litepicker .container__days .day-item.is-start-date {
  color: var(--litepicker-is-start-color) !important;
  background-color: var(--litepicker-is-start-color-bg) !important;
  position: relative;
}
.litepicker .container__days .day-item.is-start-date:after {
  content: "";
  display: block;
  height: 100%;
  width: calc(50% + 2px);
  position: absolute;
  left: auto;
  right: -2px;
  background-color: var(--litepicker-is-in-range-color);
}
.litepicker .container__days .day-item.is-start-date.is-flipped:after {
  right: auto;
  left: -2px;
}
.litepicker .container__days .day-item.is-end-date {
  color: var(--litepicker-is-end-color) !important;
  background-color: var(--litepicker-is-end-color-bg) !important;
  position: relative;
}
.litepicker .container__days .day-item.is-end-date:after {
  content: "";
  display: block;
  height: 100%;
  width: calc(50% + 2px);
  position: absolute;
  left: -2px;
  right: auto;
  background-color: var(--litepicker-is-in-range-color);
}
.litepicker .container__days .day-item.is-end-date.is-flipped:after {
  right: -2px;
  left: auto;
}
.litepicker .container__days .day-item.is-start-date.is-end-date:after {
  display: none;
}
.litepicker .container__days .day-item.is-highlighted {
  color: var(--litepicker-highlighted-day-color) !important;
  background-color: var(--litepicker-highlighted-day-color-bg) !important;
}
.litepicker[data-plugins=ranges] .container__main {
  margin-top: 0.25rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  overflow: hidden;
  border-radius: 0.35rem;
}
.litepicker[data-plugins=ranges] .container__main .container__predefined-ranges {
  box-shadow: none !important;
  border-radius: 0.35rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-top: 0.5rem;
  border-right: 1px solid #e0e5ec;
}
.litepicker[data-plugins=ranges] .container__main .container__predefined-ranges button {
  cursor: pointer !important;
}
.litepicker[data-plugins=ranges] .container__main .container__months {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-area {
    height: 15rem;
  }
}

.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-bar {
    height: 15rem;
  }
}

.chart-pie {
  position: relative;
  height: 15rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-pie {
    height: 15rem;
  }
}

.dataTable-wrapper .dataTable-container {
  font-size: 0.875rem;
}

.dataTable-wrapper.no-header .dataTable-container {
  border-top: none;
}

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: none;
}

.dataTable-top {
  padding: 0 0 1rem;
}

.dataTable-bottom {
  padding: 0;
}

.dataTable-top > nav:first-child,
.dataTable-top > div:first-child,
.dataTable-bottom > nav:first-child,
.dataTable-bottom > div:first-child {
  float: left;
}

.dataTable-top > nav:last-child,
.dataTable-top > div:last-child,
.dataTable-bottom > nav:last-child,
.dataTable-bottom > div:last-child {
  float: right;
}

.dataTable-selector {
  width: auto;
  display: inline-block;
  padding-left: 1.125rem;
  padding-right: 2.125rem;
  margin-right: 0.25rem;
}

.dataTable-info {
  margin: 7px 0;
}

/* PAGER */
.dataTable-pagination a:hover {
  background-color: #e0e5ec;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #3A071C;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.dataTable-pagination .pager a {
  font-weight: bold;
}

/* TABLE */
.dataTable-table {
  border-collapse: collapse;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: top;
  padding: 0.75rem 0.75rem;
}

.dataTable-table > thead > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: none;
}

.dataTable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th a {
  text-decoration: none;
  color: inherit;
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.dataTable-sorter::before {
  bottom: 4px;
}

.dataTable-sorter::after {
  top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

.btn-datatable {
  height: 20px !important;
  width: 20px !important;
  font-size: 0.75rem;
  border-radius: 0.35rem !important;
}

.sbp-dropdown-example .dropdown-menu {
  display: inline-block;
  position: relative;
  float: none;
  max-width: 15rem;
}

.sbp-preview {
  border-radius: 0.35rem;
  border: 0.25rem solid #e0e5ec;
  border-style: solid;
}
.sbp-preview .sbp-preview-content {
  padding: 1.5rem;
  border-bottom: 1px solid #e0e5ec;
  position: relative;
}
.sbp-preview .sbp-preview-code {
  background-color: #f2f6fc;
  padding: 1.5rem;
  border-bottom: 1px solid #e0e5ec;
}
.sbp-preview .sbp-preview-code .nav-tabs {
  border-bottom-color: transparent;
  font-size: 0.75rem;
  font-weight: 500;
}
.sbp-preview .sbp-preview-code .nav-tabs .nav-item .nav-link {
  border-bottom: 0;
  display: inline-flex;
  align-items: center;
}
.sbp-preview .sbp-preview-code .nav-tabs .nav-item .nav-link.active {
  border-color: transparent;
  background-color: #2f2f2f;
  color: white;
}
.sbp-preview .sbp-preview-code .nav-tabs .nav-item .nav-link .img-pug {
  height: 1em;
  padding: 1px 0;
}
.sbp-preview .sbp-preview-text {
  background-color: #f2f6fc;
  font-size: 0.875rem;
  padding: 1.5rem;
}

code[class*=language-],
pre[class*=language-] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  color: #eee;
  background: #2f2f2f;
  font-family: "SFMono-Regular", "Consolas", "Liberation Mono", "Menlo", monospace;
  font-size: 0.875rem;
  line-height: 1.5em;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

code[class*=language-]::-moz-selection,
pre[class*=language-]::-moz-selection,
code[class*=language-] ::-moz-selection,
pre[class*=language-] ::-moz-selection {
  background: #363636;
}

code[class*=language-]::selection,
pre[class*=language-]::selection,
code[class*=language-] ::selection,
pre[class*=language-] ::selection {
  background: #363636;
}

:not(pre) > code[class*=language-] {
  white-space: normal;
  border-radius: 0.2em;
  padding: 0.1em;
}

pre[class*=language-] {
  overflow: auto;
  position: relative;
  margin: 0;
  padding: 1rem;
  max-height: 35rem;
}

.language-css > code,
.language-sass > code,
.language-scss > code {
  color: #fd9170;
}

[class*=language-] .namespace {
  opacity: 0.7;
}

.token.atrule {
  color: #c792ea;
}

.token.attr-name {
  color: #ffcb6b;
}

.token.attr-value {
  color: #a5e844;
}

.token.attribute {
  color: #a5e844;
}

.token.boolean {
  color: #c792ea;
}

.token.builtin {
  color: #ffcb6b;
}

.token.cdata {
  color: #80cbc4;
}

.token.char {
  color: #80cbc4;
}

.token.class {
  color: #ffcb6b;
}

.token.class-name {
  color: #f2ff00;
}

.token.comment {
  color: #616161;
}

.token.constant {
  color: #c792ea;
}

.token.deleted {
  color: #ff6666;
}

.token.doctype {
  color: #616161;
}

.token.entity {
  color: #ff6666;
}

.token.function {
  color: #c792ea;
}

.token.hexcode {
  color: #f2ff00;
}

.token.id {
  color: #c792ea;
  font-weight: bold;
}

.token.important {
  color: #c792ea;
  font-weight: bold;
}

.token.inserted {
  color: #80cbc4;
}

.token.keyword {
  color: #c792ea;
}

.token.number {
  color: #fd9170;
}

.token.operator {
  color: #89ddff;
}

.token.prolog {
  color: #616161;
}

.token.property {
  color: #80cbc4;
}

.token.pseudo-class {
  color: #a5e844;
}

.token.pseudo-element {
  color: #a5e844;
}

.token.punctuation {
  color: #89ddff;
}

.token.regex {
  color: #f2ff00;
}

.token.selector {
  color: #ff6666;
}

.token.string {
  color: #a5e844;
}

.token.symbol {
  color: #c792ea;
}

.token.tag {
  color: #ff6666;
}

.token.unit {
  color: #fd9170;
}

.token.url {
  color: #ff6666;
}

.token.variable {
  color: #ff6666;
}

/*!
 * Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-family: "Font Awesome 6 Sharp";
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-at::before {
  content: "\@";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-info::before {
  content: "\f129";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-ring-diamond::before {
  content: "\e5ab";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-films::before {
  content: "\e17a";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-h1::before {
  content: "\f313";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-crop::before {
  content: "\f125";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-merge::before {
  content: "\e526";
}

.fa-pager::before {
  content: "\f815";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-trees::before {
  content: "\f724";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-page::before {
  content: "\e428";
}

.fa-k::before {
  content: "K";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-lips::before {
  content: "\f600";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-comments::before {
  content: "\f086";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-blog::before {
  content: "\f781";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-fence::before {
  content: "\e303";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-w::before {
  content: "W";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-badge::before {
  content: "\f335";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-clover::before {
  content: "\e139";
}

.fa-meat::before {
  content: "\f814";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-bird::before {
  content: "\e469";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-grill-fire::before {
  content: "\e5a4";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-radar::before {
  content: "\e024";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-film::before {
  content: "\f008";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-block::before {
  content: "\e46a";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-memory::before {
  content: "\f538";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-flag::before {
  content: "\f024";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-album::before {
  content: "\f89f";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-compress::before {
  content: "\f066";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-lock::before {
  content: "\f023";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-crosshairs-simple::before {
  content: "\e59f";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-hourglass-half::before {
  content: "\f252";
}

.fa-hourglass-2::before {
  content: "\f252";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-om::before {
  content: "\f679";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-worm::before {
  content: "\e599";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-command::before {
  content: "\e142";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-lightbulb-cfl::before {
  content: "\e5a6";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-period::before {
  content: "\.";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-i::before {
  content: "I";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-pills::before {
  content: "\f484";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-v::before {
  content: "V";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-squid::before {
  content: "\e450";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-school::before {
  content: "\f549";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-joint::before {
  content: "\f595";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-q::before {
  content: "Q";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-g::before {
  content: "G";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-option::before {
  content: "\e318";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-mug::before {
  content: "\f874";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-vial::before {
  content: "\f492";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-e::before {
  content: "E";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-h3::before {
  content: "\f315";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-user::before {
  content: "\f007";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-comma::before {
  content: "\,";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-key::before {
  content: "\f084";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-steak::before {
  content: "\f824";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-c::before {
  content: "C";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-star::before {
  content: "\f005";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-cross::before {
  content: "\f654";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-box::before {
  content: "\f466";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-spider::before {
  content: "\f717";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-window::before {
  content: "\f40e";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-slash::before {
  content: "\f715";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-server::before {
  content: "\f233";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-family::before {
  content: "\e300";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-venus::before {
  content: "\f221";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-crown::before {
  content: "\f521";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-books::before {
  content: "\f5db";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-l::before {
  content: "L";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-stars::before {
  content: "\f762";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-city::before {
  content: "\f64f";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-headset::before {
  content: "\f590";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-taco::before {
  content: "\f826";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-bold::before {
  content: "\f032";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-tilde::before {
  content: "\~";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-train::before {
  content: "\f238";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-00::before {
  content: "\e467";
}

.fa-crow::before {
  content: "\f520";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-custard::before {
  content: "\e403";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-image::before {
  content: "\f03e";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-integral::before {
  content: "\f667";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-vest::before {
  content: "\e085";
}

.fa-pig::before {
  content: "\f706";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-text::before {
  content: "\f893";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-virus::before {
  content: "\e074";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-archway::before {
  content: "\f557";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-italic::before {
  content: "\f033";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-church::before {
  content: "\f51d";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-z::before {
  content: "Z";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-a::before {
  content: "A";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-p::before {
  content: "P";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-toilet-paper-check::before {
  content: "\e5b2";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-locust::before {
  content: "\e520";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-podium::before {
  content: "\f680";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-question::before {
  content: "\?";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-toggle-large-on::before {
  content: "\e5b1";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-code::before {
  content: "\f121";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-pie::before {
  content: "\f705";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-f::before {
  content: "F";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-road::before {
  content: "\f018";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-poop::before {
  content: "\f619";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-starship::before {
  content: "\e039";
}

.fa-eject::before {
  content: "\f052";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-seal::before {
  content: "\e241";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-h::before {
  content: "H";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-heart::before {
  content: "\f004";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-folders::before {
  content: "\f660";
}

.fa-angel::before {
  content: "\f779";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-r::before {
  content: "R";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-toilet-paper-xmark::before {
  content: "\e5b3";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-circle::before {
  content: "\f111";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-slider::before {
  content: "\e252";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-files::before {
  content: "\e178";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-pen::before {
  content: "\f304";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-pump::before {
  content: "\e442";
}

.fa-bus::before {
  content: "\f207";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-walker::before {
  content: "\f831";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-plant-wilt::before {
  content: "\e5aa";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-phone::before {
  content: "\f095";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-mouse-field::before {
  content: "\e5a8";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-hose::before {
  content: "\e419";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-divide::before {
  content: "\f529";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-j::before {
  content: "J";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-gamepad-modern::before {
  content: "\e5a2";
}

.fa-gamepad-alt::before {
  content: "\e5a2";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-o::before {
  content: "O";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-otter::before {
  content: "\f700";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-comment::before {
  content: "\f075";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-h6::before {
  content: "\e413";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-empty::before {
  content: "\f254";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-water::before {
  content: "\f773";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-plate-utensils::before {
  content: "\e43b";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-reel::before {
  content: "\e238";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-grate::before {
  content: "\e193";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-dna::before {
  content: "\f471";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-gif::before {
  content: "\e190";
}

.fa-chess::before {
  content: "\f439";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-vials::before {
  content: "\f493";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-split::before {
  content: "\e254";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-cannister::before {
  content: "\f8b7";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-u::before {
  content: "U";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-overline::before {
  content: "\f876";
}

.fa-s::before {
  content: "S";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-olive::before {
  content: "\e316";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-people::before {
  content: "\e216";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-images::before {
  content: "\f302";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-n::before {
  content: "N";
}

.fa-garage::before {
  content: "\e009";
}

.fa-cable-car::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-download::before {
  content: "\f019";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-file-circle-check::before {
  content: "\e5a0";
}

.fa-glass::before {
  content: "\f804";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-b::before {
  content: "B";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-pipe::before {
  content: "\|";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-potato::before {
  content: "\e440";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-upload::before {
  content: "\f093";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-mars::before {
  content: "\f222";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-notes::before {
  content: "\e202";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-sun::before {
  content: "\f185";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-industry::before {
  content: "\f275";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-grill::before {
  content: "\e5a3";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-less-than::before {
  content: "\<";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-person-through-window::before {
  content: "\e5a9";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-file-circle-xmark::before {
  content: "\e5a1";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-bed::before {
  content: "\f236";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-socks::before {
  content: "\f696";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-section::before {
  content: "\e447";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-drum::before {
  content: "\f569";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-grill-hot::before {
  content: "\e5a5";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-play::before {
  content: "\f04b";
}

.fa-font::before {
  content: "\f031";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-colon::before {
  content: "\:";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-club::before {
  content: "\f327";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-y::before {
  content: "Y";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-fish::before {
  content: "\f578";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-expand::before {
  content: "\f065";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-fort::before {
  content: "\e486";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-h2::before {
  content: "\f314";
}

.fa-equals::before {
  content: "\=";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-blender::before {
  content: "\f517";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-map::before {
  content: "\f279";
}

.fa-file-circle-info::before {
  content: "\e493";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-store::before {
  content: "\f54e";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-fill::before {
  content: "\f575";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-person-to-door::before {
  content: "\e433";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-frame::before {
  content: "\e495";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-display::before {
  content: "\e163";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-robot::before {
  content: "\f544";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-award::before {
  content: "\f559";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-ditto::before {
  content: "\"";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-tents::before {
  content: "\e582";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-moon::before {
  content: "\f186";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-music::before {
  content: "\f001";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-lightbulb-cfl-on::before {
  content: "\e5a7";
}

.fa-melon::before {
  content: "\e310";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-t::before {
  content: "T";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-fan::before {
  content: "\f863";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-haykal::before {
  content: "\f666";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-file-circle-plus::before {
  content: "\e494";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-function::before {
  content: "\f661";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-m::before {
  content: "M";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-h5::before {
  content: "\e412";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-book::before {
  content: "\f02d";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-check::before {
  content: "\f00c";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-rug::before {
  content: "\e569";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-snake::before {
  content: "\f716";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner::before {
  content: "\f488";
}

.fa-tire::before {
  content: "\f631";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-dice::before {
  content: "\f522";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-onion::before {
  content: "\e427";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-train-tram::before {
  content: "\e5b4";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-jar::before {
  content: "\e516";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-toggle-large-off::before {
  content: "\e5b0";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-boot::before {
  content: "\f782";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-plane::before {
  content: "\f072";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-print::before {
  content: "\f02f";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-x::before {
  content: "X";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-d::before {
  content: "D";
}

.fa-stapler::before {
  content: "\e5af";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-comet::before {
  content: "\e003";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

@font-face {
  font-family: "Qatar2022";
  font-style: normal;
  font-weight: 700;
  src: local("Qatar2022"), url("../fonts/Qatar2022Bold.woff") format("woff");
}
@font-face {
  font-family: "Qatar2022";
  font-style: normal;
  font-weight: 900;
  src: local("Qatar2022"), url("../fonts/Qatar2022Heavy.woff") format("woff");
}
body {
  background: #90112f;
  background: radial-gradient(circle, #90112f 0%, #6c0e28 50%, #37061b 100%);
  color: #fff;
}

.gradient-background {
  background: #90112f;
  background: radial-gradient(circle, #90112f 0%, #6c0e28 50%, #37061b 100%);
}

.no-transition {
  transition: none;
}

body img.wc-logo {
  max-width: 240px;
}

body img.ow-logo {
  max-width: 280px;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name,
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email {
  max-width: 12rem;
}

.match .team-name {
  font-size: 1.5rem;
  width: 180px;
  max-width: 180px;
}
@media (min-width: 768px) {
  .match .team-name {
    min-height: 72px;
  }
}
@media (max-width: 767.98px) {
  .match .team-name {
    font-size: 1.2rem;
  }
}
.match .vs {
  font-size: 3rem;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  text-shadow: 0 3px 12px white;
}
@media (max-width: 767.98px) {
  .match .vs {
    font-size: 2rem;
  }
}
.match .goals {
  font-size: 3.5rem;
  color: #fff;
}
@media (max-width: 575.98px) {
  .match .goals {
    font-size: 1.5rem;
  }
}
.match .score input {
  font-size: 3rem;
  max-width: 4rem;
}
@media (max-width: 575.98px) {
  .match .score input {
    font-size: 1.5rem;
  }
}
.match input.corners, .match input.yellow-cards {
  max-width: 2rem;
}
.match .progress {
  height: 2rem;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.match.card {
  --bs-bg-opacity: 0.8;
}

.nav-pills.matches {
  --bs-nav-pills-link-active-bg: #3A071C;
}

.match .progress .bg-1, .match .legend .bg-1 {
  background-color: #3B4309 !important;
}
.match .progress .bg-2, .match .legend .bg-2 {
  background-color: #09432E !important;
}
.match .progress .bg-3, .match .legend .bg-3 {
  background-color: #110943 !important;
}

.pagination, .dataTable-pagination ul {
  justify-content: center;
}

@media (min-width: 768px) {
  .offcanvas.gradient-background {
    background: none;
  }
}
@media (max-width: 575.98px) {
  .pagination .page-item:not(:first-child):not(:last-child):not(.active), .dataTable-pagination ul .page-item:not(:first-child):not(:last-child):not(.active), .pagination .dataTable-pagination li:not(:first-child):not(:last-child):not(.active), .dataTable-pagination .pagination li:not(:first-child):not(:last-child):not(.active), .dataTable-pagination ul li:not(:first-child):not(:last-child):not(.active) {
    display: none;
  }
}
.navbar .dropdown-menu {
  top: 100% !important;
}

.img-icon {
  max-width: 2rem;
}

.invalid-feedback {
  background-color: #fff;
  padding: 4px;
}

.legend .square {
  width: 1rem;
  height: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#start_game {
  /* HIDE RADIO */
  /* IMAGE STYLES */
  /* CHECKED STYLES */
}
#start_game .rounded-circle {
  aspect-ratio: 1;
}
#start_game [type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
#start_game [type=radio] + div {
  cursor: pointer;
}
#start_game [type=radio]:checked + div {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
}
#start_game img.start-logo {
  width: auto;
  height: auto;
  max-width: 64px;
  max-height: 64px;
}
@media (min-width: 768px) {
  #start_game img.start-logo {
    max-width: 80px;
    max-height: 80px;
  }
}

.dropdown-user {
  min-width: 280px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .team-logo-container {
    min-height: 48px;
    max-height: 48px;
  }
}
@media (min-width: 768px) {
  .team-logo-container {
    min-height: 80px;
    max-height: 80px;
  }
}
.team-logo-container img.team-logo {
  max-width: 120px;
  max-height: 80px;
}
@media (max-width: 767.98px) {
  .team-logo-container img.team-logo {
    max-width: 64px;
    max-height: 48px;
  }
}

#navbar-top.fixed-top, .nav-fixed #navbar-top.topnav {
  border-bottom: 1px solid #fff;
  background-color: #3A071C;
}